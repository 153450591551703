import type {
  GoogleTagManagerContainer,
  GoogleTagManagerContainerDTO,
} from '@/widget/google/tag-manager/google-tag-manager-container';

export const mapGoogleTagManagerContainerFromDTO = (
  GoogleTagManagerContainerDTO: GoogleTagManagerContainerDTO,
): GoogleTagManagerContainer => ({
  containerId: GoogleTagManagerContainerDTO.containerId,
});
