<template>
  <div
    class="col-span-2"
    :data-test="`BookingSummaryBodyUnitItemContingencyCharge__${contingencyChargeUnit.unitId}`"
  >
    <p>
      {{
        $t('includesContingencyChargeOfAmount', {
          amount: $n(contingencyChargeUnit.amount, {
            style: 'currency',
            currency: searchStore.activeProperty.currencyCode,
          }),
          contingencyChargeName,
        })
      }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { useContingencyChargeName } from '@/contingency-charge/name/contingency-charge-name.composable';
import type { PropertyContingencyCharge } from '@/contingency-charge/property/property-contingency-charge';
import type { PropertyContingencyChargeUnit } from '@/contingency-charge/property/unit/property-contingency-charge-unit';
import { useSearchStore } from '@/search/search.store';

const { contingencyCharge } = defineProps<{
  contingencyCharge: PropertyContingencyCharge;
  contingencyChargeUnit: PropertyContingencyChargeUnit;
}>();

const searchStore = useSearchStore();

const contingencyChargeName = useContingencyChargeName(() => contingencyCharge);
</script>
