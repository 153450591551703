import { countries } from 'country-flag-icons';
import { getWeekStartByLocale } from 'weekstart';

/**
 * Returns the display name of the language associated with the given language locale.
 * If no language can be found for the locale, an empty string will be returned.
 *
 * For example: 'en-GB' -> 'English (UK)', 'en' -> 'English'.
 *
 * By default, the display name will be translated using the same language locale.
 * Optionally, a separate translation locale may be provided to translate in to
 * a different language.
 */
export const getDisplayNameOfLocaleLanguage = (
  languageLocale: string,
  {
    translationLocale,
    displayNameOptions,
  }: {
    translationLocale?: string;
    displayNameOptions?: Omit<Intl.DisplayNamesOptions, 'type'>;
  } = {},
): string => {
  const languageDisplayNames = new Intl.DisplayNames(
    translationLocale ?? languageLocale,
    {
      ...displayNameOptions,
      type: 'language',
      fallback: 'none',
    },
  );

  return languageDisplayNames.of(languageLocale) ?? '';
};

/**
 * For example: 'en-GB' -> 'en', 'en' -> 'en'.
 */
export const getLanguageCodeOfLocale = (locale: string): string =>
  new Intl.Locale(locale).language;

/**
 * Returns the region code of the region associated with the given locale. If the
 * locale is provided with no region, an empty string will be returned.
 *
 * For example: 'en-GB' -> 'GB', 'en' -> ''.
 */
export const getRegionCodeOfLocale = (locale: string): string =>
  new Intl.Locale(locale).region ?? '';

/**
 * Returns the week start 'index' of a locale. The index is a number in the
 * range 0 to 6, where 0 = Sunday, 6 = Saturday.
 *
 * Ideally, we would use `getWeekInfo` from the Intl API to do this instead.
 * However, browser support still seems to be quite poor, and most of those
 * that do implement it use non-standard implementations (e.g., accessors
 * over methods).
 *
 * Additionally, the current version of Typescript does not include built-in
 * types for this method.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo}
 */
export const getWeekStartIndexOfLocale = (
  locale: string,
): 0 | 1 | 2 | 3 | 4 | 5 | 6 => getWeekStartByLocale(locale);

/**
 * Returns the display name of the region associated with the given regional locale.
 * If the locale is provided with no region, an empty string will be returned.
 *
 * For example: 'en-GB' -> 'United Kingdom', 'en' -> ''.
 *
 * By default, the display name will be translated using the same regional locale.
 * Optionally, a separate translation locale may be provided to translate in to
 * a different language.
 */
export const getDisplayNameOfLocaleRegion = (
  regionalLocale: string,
  translationLocale?: string,
): string => {
  const regionCode = getRegionCodeOfLocale(regionalLocale);

  return regionCode
    ? getDisplayNameOfRegionCode(
        regionCode,
        translationLocale ?? regionalLocale,
      )
    : '';
};

/**
 * Returns the display name of the given region code. If no region can be found
 * for the region code, an empty string will be returned.
 *
 * For example: 'GB' -> 'United Kingdom'.
 *
 * The display name will be translated using the given translation locale.
 */
export const getDisplayNameOfRegionCode = (
  regionCode: string,
  translationLocale: string,
): string => {
  const regionDisplayNames = new Intl.DisplayNames(translationLocale, {
    type: 'region',
  });

  return regionCode ? (regionDisplayNames.of(regionCode) ?? '') : '';
};

/**
 * Returns a list of all ISO-3166-1 regions.
 */
export const getRegionCodes = (): string[] => countries;
