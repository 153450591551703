import type {
  ContingencyChargeTax,
  ContingencyChargeTaxDTO,
} from '@/contingency-charge/tax/contingency-charge-tax';

export const mapContingencyChargeTaxFromDTO = (
  contingencyChargeTaxDTO: ContingencyChargeTaxDTO,
): ContingencyChargeTax => ({
  percentage: Number(contingencyChargeTaxDTO.percentage),
  isIncluded: contingencyChargeTaxDTO.isIncluded,
});
