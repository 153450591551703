<template>
  <div>
    <AppButton
      v-if="quantity === 0"
      intent="primary"
      class="h-[3rem] w-full"
      :text="props.buttonText"
      @click="quantity += props.numberToSelect"
    />

    <AppNumberSpinner
      v-else
      v-model="quantity"
      class="h-[3rem]"
      :item-name="itemName"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppNumberSpinner from '@/ui/app-number-spinner/AppNumberSpinner.vue';

const props = withDefaults(
  defineProps<{
    itemName: string;
    numberToSelect?: number;
    buttonText?: string;
  }>(),
  {
    numberToSelect: 1,
    buttonText: () => {
      const { t } = useI18n();
      const { toTitleCase } = useLocaleString();

      return toTitleCase(t('select'));
    },
  },
);

const quantity = defineModel<number>({ required: true });
</script>
