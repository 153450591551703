<template>
  <AppLink class="text-sm" :text="buttonText" @click="updateSelectedMeal" />
</template>

<script setup lang="ts">
import { pull } from 'lodash-es';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBookingSummaryMealsStore } from '@/booking-summary/body/unit-item/meals/booking-summary-meals.store';
import { getMealTranslationKey } from '@/property/meal/meal.utilities';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLink from '@/ui/app-link/AppLink.vue';

const props = defineProps<{ meal: SupplementalMeal }>();

const { t } = useI18n();
const { toTitleCase } = useLocaleString();

const { unitItineraryItem, getNumberFormattedTotalRateForMeal } =
  useBookingSummaryMealsStore();

const mealTypeName = computed(() => t(getMealTranslationKey(props.meal)));

const isMealSelected = computed(() =>
  unitItineraryItem.selectedMealTypes.includes(props.meal.type),
);

const numberFormattedTotalRate = computed(() =>
  getNumberFormattedTotalRateForMeal(props.meal),
);

const buttonText = computed(() =>
  toTitleCase(
    isMealSelected.value
      ? t('removeMealName', { mealName: mealTypeName.value })
      : `${t('addMealName', {
          mealName: mealTypeName.value,
        })} (+${numberFormattedTotalRate.value})`,
  ),
);

const updateSelectedMeal = () => {
  if (isMealSelected.value) {
    pull(unitItineraryItem.selectedMealTypes, props.meal.type);
  } else {
    unitItineraryItem.selectedMealTypes.push(props.meal.type);
  }
};
</script>
