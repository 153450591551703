<template>
  <div
    data-test="BookingSummaryBodyUnitItem"
    class="grid grid-cols-[auto_min-content] text-sm"
  >
    <div class="break-anywhere">
      <span class="text-base font-semibold" translate="yes">
        {{
          showRoomNumber
            ? $t('numberedUnitName', {
                unitNumber,
                unitName: unitItineraryItemStore.unit.name,
              })
            : unitItineraryItemStore.unit.name
        }}
      </span>

      <span
        v-if="unitItineraryItemStore.wayToSell"
        class="pl-1 text-neutral-500"
        translate="yes"
      >
        ({{ unitItineraryItemStore.wayToSell.name }})
      </span>
    </div>

    <div class="self-center justify-self-end">
      <BookingSummaryBodyUnitItemRemoveButton
        :unit-itinerary-item-id="unitItineraryItem.id"
      />
    </div>

    <BookingSummaryBodyUnitItemDiscountTag :unit-itinerary-item />

    <div>
      <BookingSummaryBodyUnitItemRateTag :unit-itinerary-item />

      <div class="text-neutral-500">
        {{ occupancyDisplayTextWithOccupantAges }}
      </div>
    </div>

    <BookingSummaryBodyUnitItemRate :unit-itinerary-item />

    <BookingSummaryBodyUnitItemGuestDetails :unit-itinerary-item />

    <BookingSummaryBodyUnitItemMeals :unit-itinerary-item />

    <BookingSummaryBodyUnitItemContingencyCharge
      v-if="contingencyCharge && contingencyChargeUnit"
      :contingency-charge
      :contingency-charge-unit
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import BookingSummaryBodyUnitItemDiscountTag from '@/booking-summary/body/unit-item/BookingSummaryBodyUnitItemDiscountTag.vue';
import BookingSummaryBodyUnitItemGuestDetails from '@/booking-summary/body/unit-item/BookingSummaryBodyUnitItemGuestDetails.vue';
import BookingSummaryBodyUnitItemRate from '@/booking-summary/body/unit-item/BookingSummaryBodyUnitItemRate.vue';
import BookingSummaryBodyUnitItemRateTag from '@/booking-summary/body/unit-item/BookingSummaryBodyUnitItemRateTag.vue';
import BookingSummaryBodyUnitItemContingencyCharge from '@/booking-summary/body/unit-item/contingency-charge/BookingSummaryBodyUnitItemContingencyCharge.vue';
import BookingSummaryBodyUnitItemMeals from '@/booking-summary/body/unit-item/meals/BookingSummaryBodyUnitItemMeals.vue';
import BookingSummaryBodyUnitItemRemoveButton from '@/booking-summary/body/unit-item/remove-button/BookingSummaryBodyUnitItemRemoveButton.vue';
import { findPropertyContingencyChargeUnitById } from '@/contingency-charge/property/property-contingency-charge.utilities';
import { useOccupancy } from '@/occupancy/occupancy.composable';
import { useSearchStore } from '@/search/search.store';

const props = defineProps<{
  unitItineraryItem: UnitItineraryItem;
  unitNumber: number;
  showRoomNumber: boolean;
}>();

const searchStore = useSearchStore();
const unitItineraryItemStore = useUnitItineraryItemStore(
  props.unitItineraryItem.id,
);

const { generateBasicDisplayTextWithOccupantAges } = useOccupancy();
const occupancyDisplayTextWithOccupantAges = computed(() =>
  generateBasicDisplayTextWithOccupantAges(props.unitItineraryItem.occupancy),
);

const contingencyCharge = computed(
  () => searchStore.activeProperty.contingencyCharge,
);

const contingencyChargeUnit = computed(() =>
  contingencyCharge.value
    ? findPropertyContingencyChargeUnitById(
        contingencyCharge.value,
        props.unitItineraryItem.unitId,
      )
    : undefined,
);
</script>
