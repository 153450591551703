import { computed, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getContingencyChargeNameTranslationKey } from '@/contingency-charge/contingency-charge.utilities';
import type { Property } from '@/property/property';

export const usePropertyContingencyChargeTermsAndConditionsDisplayText = (
  property: Ref<Property>,
  selectedUnitIds: Ref<number[]>,
) => {
  const { t } = useI18n();

  return computed(() =>
    property.value.contingencyCharge &&
    property.value.contingencyCharge.isIncludedInDeposit &&
    property.value.contingencyCharge.units.some(({ unitId }) =>
      selectedUnitIds.value.includes(unitId),
    )
      ? `Placeholder text about the refundability of ${t(getContingencyChargeNameTranslationKey(property.value.contingencyCharge))}.`
      : '',
  );
};
