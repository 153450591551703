<template>
  <button
    :class="link({ underlined })"
    :disabled="disabled"
    :aria-label="label ?? text"
  >
    <span v-if="!!$slots.icon">
      <slot name="icon"></slot>
    </span>

    <div class="truncate">{{ text }}</div>
  </button>
</template>

<script setup lang="ts">
import { cva, type VariantProps } from 'class-variance-authority';
import { combineClasses, type ClassValue } from '@/ui/ui.utilities';

const {
  class: classNames,
  underlined = undefined,
  disabled = false,
} = defineProps<{
  text: string;
  underlined?: boolean;
  disabled?: boolean;
  label?: string;
  class?: ClassValue;
}>();

const linkVariants = cva(
  [
    'inline-flex',
    'gap-2',
    'max-w-full',
    'items-center',
    'text-left',
    'outline-none',
    'focus-visible:rounded-sm',
    'focus-visible:outline',
    'focus-visible:outline-1',
    'focus-visible:outline-offset-2',
    'focus-visible:outline-inherit',
    'disabled:text-neutral-300',
  ],
  {
    variants: {
      underlined: {
        true: ['underline'],
        false: ['hover:underline'],
      },
    },
    defaultVariants: {
      underlined: true,
    },
  },
);

const link = (variants: LinkVariants) =>
  combineClasses(linkVariants(variants), classNames);

type LinkVariants = VariantProps<typeof linkVariants>;
</script>
