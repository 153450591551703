import type { DailyPropertyAvailability } from '@/availability/daily-property-availability/daily-property-availability';
import { findDailyPropertyAvailabilityUnitAvailabilityByUnitIdOrFail } from '@/availability/daily-property-availability/daily-property-availability.utilities';
import { isPseudoUnitAvailable } from '@/availability/pseudo-unit-availability/pseudo-unit-availability.utilities';
import {
  type AvailabilityStatus,
  AvailabilityStatusType,
} from '@/availability-calendar/availability-status/availability-status';
import type { Property } from '@/property/property';
import { isDateCurrentlyAllowedByPropertyLateBookingThresholds } from '@/property/property.utilities';

export const getAvailabilityForArrival = (
  dailyPropertyAvailability: DailyPropertyAvailability,
  property: Property,
  unitIds: number[],
): AvailabilityStatus => {
  let unitIdsEligibleForStay: number[] = [];

  unitIdsEligibleForStay = getUnitIdsNotClosedOut(
    [dailyPropertyAvailability],
    unitIds,
  );

  if (unitIdsEligibleForStay.length === 0) {
    return {
      isAvailable: false,
      reason: { type: AvailabilityStatusType.NotAvailable },
    };
  }

  unitIdsEligibleForStay = getUnitIdsNotSoldOut(
    [dailyPropertyAvailability],
    unitIdsEligibleForStay,
  );

  if (unitIdsEligibleForStay.length === 0) {
    return {
      isAvailable: false,
      reason: { type: AvailabilityStatusType.SoldOut },
    };
  }

  if (
    dailyPropertyAvailability.isClosedToArrival ||
    !isDateCurrentlyAllowedByPropertyLateBookingThresholds(
      dailyPropertyAvailability.date,
      property,
    )
  ) {
    return {
      isAvailable: false,
      reason: { type: AvailabilityStatusType.ClosedToArrival },
    };
  }

  const minimumStay = getMinimumStayLength(
    dailyPropertyAvailability,
    unitIdsEligibleForStay,
  );

  return {
    isAvailable: true,
    minimumStay,
  };
};

export const getAvailabilityForDeparture = (
  dailyPropertyAvailabilities: DailyPropertyAvailability[],
  unitIds: number[],
): AvailabilityStatus => {
  const arrivalAvailability = dailyPropertyAvailabilities.at(0);
  const departureAvailability = dailyPropertyAvailabilities.at(-1);
  const dailyAvailabilitiesToCheck = dailyPropertyAvailabilities.slice(0, -1);
  let unitIdsEligibleForStay: number[] = [];

  unitIdsEligibleForStay = getUnitIdsNotClosedOut(
    dailyAvailabilitiesToCheck,
    unitIds,
  );

  if (unitIdsEligibleForStay.length === 0) {
    return {
      isAvailable: false,
      reason: { type: AvailabilityStatusType.NotAvailable },
    };
  }

  unitIdsEligibleForStay = getUnitIdsNotSoldOut(
    dailyAvailabilitiesToCheck,
    unitIdsEligibleForStay,
  );

  if (unitIdsEligibleForStay.length === 0) {
    return {
      isAvailable: false,
      reason: { type: AvailabilityStatusType.SoldOut },
    };
  }

  const minimumStay = getMinimumStayLength(
    arrivalAvailability!,
    unitIdsEligibleForStay,
  );

  if (dailyPropertyAvailabilities.length <= minimumStay) {
    return {
      isAvailable: false,
      reason: {
        type: AvailabilityStatusType.MinimumStay,
        minimumStay,
      },
    };
  }

  if (departureAvailability!.isClosedToDeparture) {
    return {
      isAvailable: false,
      reason: { type: AvailabilityStatusType.ClosedToDeparture },
    };
  }

  return {
    isAvailable: true,
    minimumStay,
  };
};

export const getUnitIdsNotClosedOut = (
  dailyPropertyAvailabilities: DailyPropertyAvailability[],
  unitIds: number[],
): number[] =>
  unitIds.filter((unitId) =>
    dailyPropertyAvailabilities.every((dailyPropertyAvailability) => {
      const { rate, isClosedOut } =
        findDailyPropertyAvailabilityUnitAvailabilityByUnitIdOrFail(
          dailyPropertyAvailability,
          unitId,
        );

      return rate !== 0 && !isClosedOut;
    }),
  );

export const getMinimumStayLength = (
  dailyPropertyAvailability: DailyPropertyAvailability,
  unitIds: number[],
): number =>
  Math.min(
    ...unitIds.map((unitId) => {
      const { minimumStay } =
        findDailyPropertyAvailabilityUnitAvailabilityByUnitIdOrFail(
          dailyPropertyAvailability,
          unitId,
        );

      return minimumStay;
    }),
  );

export const getUnitIdsNotSoldOut = (
  dailyPropertyAvailabilities: DailyPropertyAvailability[],
  unitIds: number[],
): number[] => {
  return unitIds.filter((unitId) => {
    let minimumUnitAllocation;
    const pseudoUnitIds = new Set<number>();
    const unavailablePseudoUnitIds = new Set<number>();

    for (const dailyPropertyAvailability of dailyPropertyAvailabilities) {
      const unitAvailability =
        findDailyPropertyAvailabilityUnitAvailabilityByUnitIdOrFail(
          dailyPropertyAvailability,
          unitId,
        );

      if (unitAvailability.isClosedOut || unitAvailability.rate === 0) {
        return false;
      }

      if (
        typeof minimumUnitAllocation === 'undefined' ||
        unitAvailability.allocation < minimumUnitAllocation
      ) {
        minimumUnitAllocation = unitAvailability.allocation;
      }

      for (const pseudoUnitAvailability of unitAvailability.pseudoUnitAvailabilities) {
        const pseudoUnitId = pseudoUnitAvailability.pseudoUnitId;

        pseudoUnitIds.add(pseudoUnitId);

        if (!isPseudoUnitAvailable(pseudoUnitAvailability)) {
          unavailablePseudoUnitIds.add(pseudoUnitId);
        }
      }
    }
    const numberOfUnitsAvailable = calculateNumberOfAvailableUnits(
      pseudoUnitIds.size,
      unavailablePseudoUnitIds.size,
      minimumUnitAllocation ?? 0,
    );
    return numberOfUnitsAvailable > 0;
  });
};

export const getDatesToUnavailabilityMap = (
  dailyPropertyAvailabilities: (DailyPropertyAvailability | undefined)[],
  unitIds: number[],
): Map<string, boolean> => {
  const dateToSoldOutMap = new Map<string, boolean>();
  unitIds.forEach((unitId) => {
    let minimumUnitAllocation: number | undefined;
    const pseudoUnitIds = new Set<number>();
    const unavailablePseudoUnitIds = new Set<number>();

    for (const dailyPropertyAvailability of dailyPropertyAvailabilities) {
      if (dailyPropertyAvailability === undefined) continue;

      const unitAvailability =
        findDailyPropertyAvailabilityUnitAvailabilityByUnitIdOrFail(
          dailyPropertyAvailability,
          unitId,
        );

      if (
        typeof minimumUnitAllocation === 'undefined' ||
        unitAvailability.allocation < minimumUnitAllocation
      ) {
        minimumUnitAllocation = unitAvailability.allocation;
      }

      for (const pseudoUnitAvailability of unitAvailability.pseudoUnitAvailabilities) {
        const pseudoUnitId = pseudoUnitAvailability.pseudoUnitId;

        pseudoUnitIds.add(pseudoUnitId);

        if (!isPseudoUnitAvailable(pseudoUnitAvailability)) {
          unavailablePseudoUnitIds.add(pseudoUnitId);
        }
      }

      const numberOfUnitsAvailable = calculateNumberOfAvailableUnits(
        pseudoUnitIds.size,
        unavailablePseudoUnitIds.size,
        minimumUnitAllocation,
      );

      const isDateUnavailableForUnit =
        unitAvailability.isClosedOut ||
        unitAvailability.rate === 0 ||
        numberOfUnitsAvailable <= 0;

      const hasSomeUnitAvailableOnDate =
        dateToSoldOutMap.get(dailyPropertyAvailability.date) === false;

      if (isDateUnavailableForUnit && !hasSomeUnitAvailableOnDate) {
        dateToSoldOutMap.set(dailyPropertyAvailability.date, true);
      } else {
        dateToSoldOutMap.set(dailyPropertyAvailability.date, false);
      }
    }
  });
  return dateToSoldOutMap;
};

export const calculateNumberOfAvailableUnits = (
  numberOfPseudoUnits: number,
  numberOfUnavailablePseudoUnits: number,
  unitAllocation: number,
) => {
  const numberOfAvailablePseudoUnits =
    numberOfPseudoUnits - numberOfUnavailablePseudoUnits;

  return numberOfPseudoUnits > 0
    ? Math.min(unitAllocation, numberOfAvailablePseudoUnits)
    : unitAllocation;
};

export const getMinimumStayFromAvailabilityStatus = (
  availabilityStatus: AvailabilityStatus,
) =>
  availabilityStatus.isAvailable
    ? availabilityStatus.minimumStay
    : availabilityStatus.reason.type === AvailabilityStatusType.MinimumStay
      ? availabilityStatus.reason.minimumStay
      : undefined;
