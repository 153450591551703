<template>
  <div class="flex items-center justify-between space-x-4">
    <AppButton
      intent="secondary"
      class="w-full"
      :text="toTitleCase($t('cancel'))"
      @click="$emit('close')"
    />

    <AppButton
      intent="primary"
      class="w-full"
      :text="toTitleCase($t('continue'))"
      @click="onContinue"
    />
  </div>
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import router, { UNIT_SELECTION_ROUTE } from '@/router';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';

const props = defineProps<{
  unitItineraryItemId: string;
}>();

const { toTitleCase } = useLocaleString();

const bookingItineraryStore = useBookingItineraryStore();

const emit = defineEmits(['close']);

const onContinue = () => {
  bookingItineraryStore.removeUnitItineraryItemById(props.unitItineraryItemId);

  void router.replace({
    name: UNIT_SELECTION_ROUTE,
  });

  emit('close');
};
</script>
