import { computed, toValue, type MaybeRefOrGetter } from 'vue';
import { useI18n } from 'vue-i18n';
import { type ContingencyCharge } from '@/contingency-charge/contingency-charge';
import { getContingencyChargeNameTranslationKey } from '@/contingency-charge/contingency-charge.utilities';

export const useContingencyChargeName = (
  contingencyCharge: MaybeRefOrGetter<ContingencyCharge>,
) => {
  const { t } = useI18n();

  return computed(() =>
    t(getContingencyChargeNameTranslationKey(toValue(contingencyCharge))),
  );
};
