<template>
  <Disclosure
    v-if="pricePlanLists.length > 0"
    v-slot="{ open: isDisclosureOpen }"
  >
    <div class="relative mt-10" :class="{ 'mb-10': isDisclosureOpen }">
      <div
        class="absolute top-1/2 -z-10 w-full border-t border-neutral-200"
      ></div>

      <div class="flex justify-center">
        <DisclosureButton as="template">
          <AppButton
            v-show="!searchStore.isLoading"
            intent="secondary"
            class="max-w-[80%]"
            :text="
              toTitleCase(
                isDisclosureOpen
                  ? $t('hideAdditionalPrices')
                  : $t('morePricesAvailable', firstPricePlanList.length),
              )
            "
          />
        </DisclosureButton>

        <div
          v-show="
            numberSelected > 0 && !isDisclosureOpen && !searchStore.isLoading
          "
          class="pointer-events-none absolute bottom-0 z-[1] translate-y-[50%] rounded-full border-2 border-yellow-500 bg-white px-2 text-xs capitalize text-yellow-700"
        >
          {{ $t('countSelected', { count: numberSelected }) }}
        </div>
      </div>
    </div>

    <DisclosurePanel :unmount="false">
      <UnitSelectionPricePlanList :price-plans="firstPricePlanList" />

      <UnitSelectionPricePlanListDisclosure
        :price-plan-lists="remainingPricePlanLists"
        :is-last-unit="isLastUnit"
      />
    </DisclosurePanel>
  </Disclosure>

  <div v-else-if="!isLastUnit" class="border-b border-neutral-200 pb-10"></div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { computed } from 'vue';
import { countPricePlanQuantitySelectedInUnitItinerary } from '@/booking-itinerary/booking-itinerary.utilities';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { PricePlan } from '@/price-plan/price-plan';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';
import UnitSelectionPricePlanList from '@/unit-selection-page/price-plan/list/UnitSelectionPricePlanList.vue';

const props = defineProps<{
  pricePlanLists: PricePlan[][];
  isLastUnit: boolean;
}>();

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();

const { toTitleCase } = useLocaleString();

const firstPricePlanList = computed(() => props.pricePlanLists[0]!);

const remainingPricePlanLists = computed(() => props.pricePlanLists.slice(1));

const numberSelected = computed(() =>
  props.pricePlanLists
    .flat()
    .reduce(
      (count, pricePlan) =>
        count +
        countPricePlanQuantitySelectedInUnitItinerary(
          pricePlan,
          bookingItineraryStore.unitItinerary,
        ),
      0,
    ),
);
</script>
