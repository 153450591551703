import { mapPropertyFromDTO } from '@/property/property.mapper';
import { mapChainFromDTO } from '@/widget/chain/chain.mapper';
import { mapGoogleTagManagerContainerFromDTO } from '@/widget/google/tag-manager/google-tag-manager-container.mapper';
import type { Widget, WidgetDTO } from '@/widget/widget';

export const mapWidgetFromDTO = (widgetDTO: WidgetDTO): Widget => ({
  id: widgetDTO.id,
  properties: widgetDTO.properties.map(mapPropertyFromDTO),
  rankOrderedPropertyIds: widgetDTO.rankOrderedPropertyIds,
  chain: widgetDTO.chain ? mapChainFromDTO(widgetDTO.chain) : undefined,
  unavailableSearchResultsPreference:
    widgetDTO.unavailableSearchResultsPreference,
  calendarAvailabilityAwareness: widgetDTO.calendarAvailabilityAwareness,
  googleTagManagerContainer: widgetDTO.googleTagManagerContainer
    ? mapGoogleTagManagerContainerFromDTO(widgetDTO.googleTagManagerContainer)
    : undefined,
});
