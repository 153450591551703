<template>
  <li data-test="BookingSummaryBodyCodeResource">
    <div class="text-lg font-semibold capitalize">
      {{ $t('activePromocode') }}
    </div>

    <div class="mt-4 flex items-center justify-between">
      <div class="break-anywhere font-semibold">
        {{ codeResource.code }}
      </div>

      <div>
        <AppLink
          :text="$t('remove')"
          class="text-xs font-medium capitalize text-gray-600"
          @click="onRemove"
        />
      </div>
    </div>

    <div class="text-sm text-neutral-500">
      <BookingSummaryBodyCodeResourcePromocode
        v-if="codeResource.type === CodeResourceType.Promocode"
        :promocode="codeResource.promocode"
      />

      <BookingSummaryBodyCodeResourcePrivateRateOverride
        v-else
        :show-removal-warning="showPrivateRateOverrideRemovalWarning"
        @removal-cancel="showPrivateRateOverrideRemovalWarning = false"
        @removal-confirm="onPrivateRateOverrideRemovalConfirm"
      />
    </div>
  </li>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { BookingStageType } from '@/booking-stage/booking-stage';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import BookingSummaryBodyCodeResourcePrivateRateOverride from '@/booking-summary/body/code-resource/private-rate-override/BookingSummaryBodyCodeResourcePrivateRateOverride.vue';
import BookingSummaryBodyCodeResourcePromocode from '@/booking-summary/body/code-resource/promocode/BookingSummaryBodyCodeResourcePromocode.vue';
import {
  CodeResourceType,
  type CodeResource,
} from '@/code/resource/code-resource';
import AppLink from '@/ui/app-link/AppLink.vue';

const props = defineProps<{ codeResource: CodeResource }>();

const emit = defineEmits(['remove']);

const bookingStageStore = useBookingStageStore();

const showPrivateRateOverrideRemovalWarning = ref(false);

const onRemove = () => {
  if (
    props.codeResource.type === CodeResourceType.PrivateRateOverride &&
    bookingStageStore.currentStage.type !== BookingStageType.UnitSelection
  ) {
    showPrivateRateOverrideRemovalWarning.value = true;
  } else {
    emit('remove');
  }
};

const onPrivateRateOverrideRemovalConfirm = async () => {
  await bookingStageStore.goToStage(bookingStageStore.unitSelectionStage, true);

  showPrivateRateOverrideRemovalWarning.value = false;

  emit('remove');
};
</script>
