import { computed, reactive } from 'vue';
import { useBookingSourceStore } from '@/booking-source/booking-source.store';
import { filterDuplicateObjects } from '@/object/object.utilities';
import type { Occupancy } from '@/occupancy/occupancy';
import { getTotalNumberOfAdultsInOccupancies } from '@/occupancy/occupancy.utilities';
import { type PricePlan } from '@/price-plan/price-plan';
import { createPricePlans } from '@/price-plan/price-plan-creation.utilities';
import { organizeUnitPricePlans } from '@/price-plan/price-plan-organization.utilities';
import {
  comparePricePlansByOccupancyAndSearchOccupancyEquality,
  comparePricePlansByRate,
  comparePricePlansByUnitPriority,
  comparePricePlansByWhetherOccupancyCanFitSearchOccupancy,
  isPricePlanSearchOccupancyLargerThanOccupancy,
} from '@/price-plan/price-plan.utilities';
import { UnitOrderPreference } from '@/property/property';
import type { PropertyOverStay } from '@/property/property-over-stay/property-over-stay';
import { UNIT_TYPE_NAME_BED } from '@/property/unit/type/unit-type';
import { findUnitMeals } from '@/property/unit/unit.utilities';
import type { UnitRates } from '@/rates/unit-rates/unit-rates';
import { createUnitRates } from '@/rates/unit-rates/unit-rates.utilities';

export const usePropertyPricePlans = (
  propertyOverStay: PropertyOverStay,
  occupancies: Occupancy[],
) => {
  const bookingSourceStore = useBookingSourceStore();

  const {
    property,
    availableUnitsOverStay,
    propertyAvailability,
    codeResource,
    stayDates,
    offers,
    includedMeals,
    supplementalMeals,
    findUnitOverStayByUnitIdOrFail,
  } = propertyOverStay;

  const uniqueOccupancies = computed(() => filterDuplicateObjects(occupancies));

  const unitToAllUnitRatesMapping = computed<ReadonlyMap<number, UnitRates[]>>(
    () =>
      new Map(
        availableUnitsOverStay.map(({ unit, availableWaysToSell }) => [
          unit.id,
          createUnitRates(
            propertyAvailability,
            stayDates,
            offers,
            codeResource?.promocode,
            codeResource?.privateRateOverride,
            findUnitMeals(unit, includedMeals),
            findUnitMeals(unit, supplementalMeals),
            unit,
            availableWaysToSell,
            unit.type.name === UNIT_TYPE_NAME_BED
              ? [{ numberOfAdults: 1, children: [] }]
              : uniqueOccupancies.value,
          ),
        ]),
      ),
  );

  const unitToPricePlansMapping = computed<ReadonlyMap<number, PricePlan[]>>(
    () =>
      new Map(
        Array.from(
          unitToAllUnitRatesMapping.value,
          ([unitId, allUnitRates]) => [unitId, createPricePlans(allUnitRates)],
        ),
      ),
  );

  const unitToOrganizedPricePlansMapping = computed<
    ReadonlyMap<number, PricePlan[][]>
  >(
    () =>
      new Map(
        Array.from(unitToPricePlansMapping.value, ([unitId, pricePlans]) => [
          unitId,
          organizeUnitPricePlans(pricePlans),
        ]),
      ),
  );

  const unitToFirstPricePlanMapping = computed<ReadonlyMap<number, PricePlan>>(
    () =>
      new Map(
        Array.from(
          unitToOrganizedPricePlansMapping.value,
          ([unitId, pricePlans]) => [unitId, pricePlans[0]![0]!],
        ),
      ),
  );

  const firstPricePlanForAllUnits = computed<PricePlan[]>(() => [
    ...unitToFirstPricePlanMapping.value.values(),
  ]);

  const firstPricePlansForAllUnitsSortedByDisplayPriority = computed<
    PricePlan[]
  >(() =>
    [...firstPricePlanForAllUnits.value].sort((pricePlanA, pricePlanB) => {
      if (bookingSourceStore.isGoogleVRUnit(pricePlanA.unit.id)) {
        return -1;
      }

      if (bookingSourceStore.isGoogleVRUnit(pricePlanB.unit.id)) {
        return 1;
      }

      return shouldComparePricePlansByUnitPriorityOrderOnly(
        pricePlanA,
        pricePlanB,
      )
        ? comparePricePlansByUnitPriority(
            pricePlanA,
            pricePlanB,
            property.priorityOrderedUnitIds,
          )
        : comparePricePlansByOccupancyAndSearchOccupancyEquality(
            pricePlanA,
            pricePlanB,
          ) ||
            comparePricePlansByWhetherOccupancyCanFitSearchOccupancy(
              pricePlanA,
              pricePlanB,
            ) ||
            (pricePlanA.unit.type.name === UNIT_TYPE_NAME_BED &&
            pricePlanB.unit.type.name === UNIT_TYPE_NAME_BED
              ? compareUnitTypeBedPricePlans(pricePlanA, pricePlanB)
              : 0) ||
            (shouldComparePricePlansByHighestRate.value
              ? comparePricePlansByRate(pricePlanB, pricePlanA)
              : comparePricePlansByRate(pricePlanA, pricePlanB)) ||
            comparePricePlansByUnitPriority(
              pricePlanA,
              pricePlanB,
              property.priorityOrderedUnitIds,
            );
    }),
  );

  const compareUnitTypeBedPricePlans = (
    pricePlanA: PricePlan,
    pricePlanB: PricePlan,
  ) => {
    const numberOfBedsNeeded = getTotalNumberOfAdultsInOccupancies(occupancies);

    const pricePlanACanAccommodateAdults =
      findUnitOverStayByUnitIdOrFail(pricePlanA.unit.id).numberAvailable >=
      numberOfBedsNeeded;
    const pricePlanBCanAccommodateAdults =
      findUnitOverStayByUnitIdOrFail(pricePlanB.unit.id).numberAvailable >=
      numberOfBedsNeeded;

    return +pricePlanBCanAccommodateAdults - +pricePlanACanAccommodateAdults;
  };

  const highestDisplayPriorityPricePlan = computed<PricePlan>(
    () => firstPricePlansForAllUnitsSortedByDisplayPriority.value[0]!,
  );

  const shouldComparePricePlansByUnitPriorityOrderOnly = (
    pricePlanA: PricePlan,
    pricePlanB: PricePlan,
  ): boolean =>
    property.unitOrderPreference === UnitOrderPreference.UnitPriority &&
    !bookingSourceStore.isGoogleSource &&
    !isPricePlanSearchOccupancyLargerThanOccupancy(pricePlanA) &&
    !isPricePlanSearchOccupancyLargerThanOccupancy(pricePlanB);

  const shouldComparePricePlansByHighestRate = computed(
    () =>
      property.unitOrderPreference ===
        UnitOrderPreference.BestFitOccupancyHighestPrice &&
      !bookingSourceStore.isGoogleSource,
  );

  const findPricePlansForUnitId = (unitId: number): PricePlan[] =>
    unitToPricePlansMapping.value.get(unitId)!;

  const findOrganizedPricePlansForUnitId = (unitId: number): PricePlan[][] =>
    unitToOrganizedPricePlansMapping.value.get(unitId)!;

  const findFirstPricePlanForUnitId = (unitId: number): PricePlan =>
    unitToFirstPricePlanMapping.value.get(unitId)!;

  const doAllUnitPricePlansHaveSameRate = (unitId: number): boolean => {
    const pricePlans = findPricePlansForUnitId(unitId);

    const firstPricePlan = findFirstPricePlanForUnitId(unitId);

    return pricePlans.every(({ rate }) => rate === firstPricePlan.rate);
  };

  return reactive({
    propertyOverStay,
    firstPricePlansForAllUnitsSortedByDisplayPriority,
    highestDisplayPriorityPricePlan,
    findOrganizedPricePlansForUnitId,
    findFirstPricePlanForUnitId,
    doAllUnitPricePlansHaveSameRate,
  });
};
