<template>
  <button :class="button({ intent })" :disabled="disabled || isLoading">
    <Component
      :is="isLoading ? AppLoadingSpinner : icon"
      v-if="isLoading || !!icon"
      class="h-5 w-5"
    />

    {{ text }}
  </button>
</template>

<script setup lang="ts">
import type { Icon } from '@tabler/icons-vue';
import { cva, type VariantProps } from 'class-variance-authority';
import AppLoadingSpinner from '@/ui/app-loading-spinner/AppLoadingSpinner.vue';
import { combineClasses, type ClassValue } from '@/ui/ui.utilities';

const {
  class: classNames,
  isLoading = false,
  disabled = false,
} = defineProps<{
  intent: NonNullable<ButtonVariants['intent']>;
  text: string;
  icon?: Icon | undefined;
  isLoading?: boolean;
  disabled?: boolean;
  class?: ClassValue;
}>();

const buttonVariants = cva(
  [
    'flex',
    'items-center',
    'justify-center',
    'gap-2',
    'p-3',
    'rounded',
    'border',
    'transition-[filter]',
    'outline-none',
    'hover:brightness-95',
    'focus-visible:outline',
    'focus-visible:outline-slate-900',
    'active:brightness-90',
    'disabled:cursor-not-allowed',
    'disabled:bg-slate-300',
    'disabled:text-slate-500',
    'disabled:opacity-75',
    'disabled:brightness-100',
  ],
  {
    variants: {
      intent: {
        primary: [
          'bg-accent-color',
          'border-accent-border-color',
          'text-accent-contrast-color',
          'font-semibold',
          'focus-visible:outline-2',
          'focus-visible:outline-offset-4',
        ],
        secondary: [
          'bg-white',
          'border-slate-900',
          'font-medium',
          'focus-visible:outline-1',
          'focus-visible:outline-offset-2',
        ],
      },
    },
  },
);

const button = (variants: VariantProps<typeof buttonVariants>) =>
  combineClasses(buttonVariants(variants), classNames);

type ButtonVariants = VariantProps<typeof buttonVariants>;
</script>
