<template>
  <TermsAndConditionsStatement
    :property="searchStore.activePropertyOverStay.property"
    :booking-policy="
      searchStore.activePropertyOverStay.bookingPolicyOverStay.bookingPolicy
    "
    :stay-dates="searchStore.stayDates"
    :selected-unit-ids="
      bookingItineraryStore.unitItinerary.map(({ unitId }) => unitId)
    "
    :is-non-refundable="
      bookingItineraryStore.isAnyUnitItineraryItemNonRefundable
    "
    :can-collapse-sections="canCollapseSections"
  />
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useSearchStore } from '@/search/search.store';
import TermsAndConditionsStatement from '@/terms-and-conditions/statement/TermsAndConditionsStatement.vue';

defineProps<{ canCollapseSections: boolean }>();

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();
</script>
