<template>
  <div
    :class="[
      isStageCurrent(stage) || isStageComplete(stage)
        ? 'border-blue-600'
        : stage.canAccess
          ? 'border-slate-800'
          : 'border-neutral-400',
      isHorizontal
        ? 'mx-3 w-32 min-w-[12px] border-b-2'
        : 'my-3 ml-2.5 h-8 border-l-2',
    ]"
  ></div>
</template>

<script setup lang="ts">
import type { BookingStage } from '@/booking-stage/booking-stage';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

defineProps<{
  stage: BookingStage;
  isHorizontal: boolean;
}>();

const { isStageCurrent, isStageComplete } = useBookingStageStore();
</script>
