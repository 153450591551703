<template>
  <div>
    <p
      ref="collapsibleSection"
      :style="{
        WebkitLineClamp: isCollapsed ? maxLines : 'unset',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
      }"
      class="overflow-hidden whitespace-pre-wrap"
    >
      {{ text }}
    </p>

    <AppLink
      v-show="isOverflowed || !isCollapsed"
      :text="
        isCollapsed ? toTitleCase($t('showMore')) : toTitleCase($t('showLess'))
      "
      class="mt-1 font-medium"
      @click="isCollapsed = !isCollapsed"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLink from '@/ui/app-link/AppLink.vue';

defineProps<{
  text: string;
  maxLines: number;
}>();

const { toTitleCase } = useLocaleString();

const collapsibleSection = ref<Element | null>();
const isCollapsed = ref(true);
const isOverflowed = ref(false);

const checkCollapsibleSectionOverflow = (entries: ResizeObserverEntry[]) => {
  for (const { target } of entries) {
    isOverflowed.value = 0 > target.clientHeight - target.scrollHeight;
  }
};
const resizeObserver = new ResizeObserver(checkCollapsibleSectionOverflow);
onMounted(() => {
  if (collapsibleSection.value) {
    resizeObserver.observe(collapsibleSection.value);
  }
});
onUnmounted(() => {
  if (collapsibleSection.value) {
    resizeObserver.unobserve(collapsibleSection.value);
  }
});
</script>
