<template>
  <div>
    <label :for="textAreaId">
      <div
        :class="combineClasses('mb-2 font-medium', labelClass)"
        :translate="shouldTranslateLabel ? 'yes' : 'no'"
      >
        {{ label }}
      </div>

      <textarea
        :id="textAreaId"
        v-model="input"
        :class="textarea({ isValid })"
        :rows="2"
        :maxlength="maximumNumberOfCharacters"
        :aria-describedby="characterCounterId"
      ></textarea>
    </label>

    <AppCharacterCounter
      :id="characterCounterId"
      class="mt-1 flex justify-end text-sm"
      :current-number-of-characters="input.length"
      :maximum-number-of-characters
    />
  </div>
</template>

<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { useId } from 'vue';
import AppCharacterCounter from '@/ui/app-character-counter/AppCharacterCounter.vue';
import { combineClasses, type ClassValue } from '@/ui/ui.utilities';

const input = defineModel<string>({ required: true });

const { isValid = true } = defineProps<{
  label: string;
  labelClass?: ClassValue;
  shouldTranslateLabel?: boolean;
  maximumNumberOfCharacters: number;
  isValid?: boolean;
}>();

const id = useId();

const textAreaId = `text_area_${id}`;
const characterCounterId = `character_counter_special_requests_${id}`;

const textarea = cva(
  [
    'w-full',
    'resize-y',
    'appearance-none',
    'rounded-lg',
    'p-4',
    'shadow-[0_0_0_1px]',
    'focus-visible:shadow-[0_0_0_2px]',
    'focus-visible:outline-none',
  ],
  {
    variants: {
      isValid: {
        true: [
          'bg-white',
          'shadow-stone-300',
          'focus-visible:shadow-slate-700',
        ],
        false: [
          'bg-red-500/10',
          'text-red-700',
          'shadow-red-500',
          'focus-visible:shadow-red-500',
        ],
      },
    },
  },
);
</script>
