<template>
  <div v-if="shouldUseModal">
    <UnitSelectionSearchBarButton
      :icon="IconUser"
      @click="isOccupancySelectorModalOpen = !isOccupancySelectorModalOpen"
    >
      <UnitSelectionSearchBarOccupancySelectorButtonContent />
    </UnitSelectionSearchBarButton>

    <AppModal
      :is-open="isOccupancySelectorModalOpen"
      @close="isOccupancySelectorModalOpen = false"
    >
      <AppModalDialog @close="isOccupancySelectorModalOpen = false">
        <template #header>
          <OccupancySelectorHeader />
        </template>

        <OccupancySelectorBody />

        <template #footer>
          <OccupancySelectorFooter
            :search-button-icon="IconSearch"
            :search-button-text="capitalize($t('search'))"
            @submit="
              () => {
                isOccupancySelectorModalOpen = false;
                updateUnitSelectionSearchOccupancies();
              }
            "
          />
        </template>
      </AppModalDialog>
    </AppModal>
  </div>

  <UseElementBounding v-else v-slot="{ right: popoverButtonRight }">
    <AppPopover v-slot="{ close }">
      <AppPopoverButton :as="UnitSelectionSearchBarButton" :icon="IconUser">
        <UnitSelectionSearchBarOccupancySelectorButtonContent />
      </AppPopoverButton>

      <UseElementBounding
        v-slot="{ top: popoverPanelTop }"
        class="absolute top-full translate-x-[-100%]"
        :style="{ left: `${popoverButtonRight}px` }"
      >
        <div
          class="flex"
          :style="{
            maxHeight: `calc(var(--app-height) - ${popoverPanelTop}px)`,
          }"
        >
          <AppPopoverPanel>
            <template #header>
              <OccupancySelectorHeader />
            </template>

            <OccupancySelectorBody class="mx-6 my-4 w-96" />

            <template #footer>
              <OccupancySelectorFooter
                :search-button-icon="IconSearch"
                :search-button-text="capitalize($t('search'))"
                @submit="
                  () => {
                    close();
                    updateUnitSelectionSearchOccupancies();
                  }
                "
              />
            </template>
          </AppPopoverPanel>
        </div>
      </UseElementBounding>
    </AppPopover>
  </UseElementBounding>
</template>

<script setup lang="ts">
import { IconUser, IconSearch } from '@tabler/icons-vue';
import { UseElementBounding } from '@vueuse/components';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { ref } from 'vue';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import OccupancySelectorBody from '@/occupancy-selector/OccupancySelectorBody.vue';
import OccupancySelectorFooter from '@/occupancy-selector/OccupancySelectorFooter.vue';
import OccupancySelectorHeader from '@/occupancy-selector/OccupancySelectorHeader.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import UnitSelectionSearchBarButton from '@/unit-selection-page/search-bar/button/UnitSelectionSearchBarButton.vue';
import UnitSelectionSearchBarOccupancySelectorButtonContent from '@/unit-selection-page/search-bar/occupancy-selector/UnitSelectionSearchBarOccupancySelectorButtonContent.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const shouldUseModal = useBreakpoints(breakpointsTailwind).smaller('sm');

const { updateOccupancies } = useUnitSelectionStore();

const { occupancies } = useOccupancySelectorStore();

const { capitalize } = useLocaleString();

const isOccupancySelectorModalOpen = ref(false);

const updateUnitSelectionSearchOccupancies = () => {
  void updateOccupancies(occupancies.value);
};
</script>
