<template>
  <div class="w-full">
    <UnitSelectionCustomHeader v-if="!elementsStore.usedElements" />
    <UnitSelectionSearchBar />

    <ContainerLayout>
      <div class="mx-3 space-x-5 py-4 md:flex">
        <div class="flex-[2]">
          <UnitSelectionSearchResults />
        </div>

        <div v-if="!bookingSummaryStore.showModalVersion" class="flex-1">
          <div
            class="sticky top-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.unit-selection-search-bar-height)+theme(spacing.4))]"
          >
            <InlineBookingSummary />
          </div>
        </div>
      </div>
    </ContainerLayout>

    <UnitSelectionSearchPropertyAvailabilityCalendarModal />

    <UnitSelectionStayDateMismatchWarningModal />
  </div>
</template>

<script setup lang="ts">
import { addMonths, format, startOfMonth, endOfMonth } from 'date-fns';
import { computed, onActivated, watch } from 'vue';
import {
  useProvideAvailabilityCalendarStore,
  MONTHS_TO_ADD,
} from '@/availability-calendar/availability-calendar.store';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import InlineBookingSummary from '@/booking-summary/InlineBookingSummary.vue';
import { createDateObject } from '@/date/date.utilities';
import { useElementsStore } from '@/elements/elements.store';
import ContainerLayout from '@/layouts/ContainerLayout.vue';
import { mapOccupanciesToOccupancySelectorUnitOccupancies } from '@/occupancy/occupancy.utilities';
import { useProvideOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import { UNIT_SELECTION_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionCustomHeader from '@/unit-selection-page/custom-header/UnitSelectionCustomHeader.vue';
import UnitSelectionSearchBar from '@/unit-selection-page/search-bar/UnitSelectionSearchBar.vue';
import UnitSelectionSearchPropertyAvailabilityCalendarModal from '@/unit-selection-page/search-property-availability-calendar/UnitSelectionSearchPropertyAvailabilityCalendarModal.vue';
import UnitSelectionSearchResults from '@/unit-selection-page/search-results/UnitSelectionSearchResults.vue';
import UnitSelectionStayDateMismatchWarningModal from '@/unit-selection-page/UnitSelectionStayDateMismatchWarningModal.vue';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';
import { GoogleTagManagerPageViewEventAction } from '@/widget/google/tag-manager/google-tag-manager-event-action';
import { useGoogleTagManagerStore } from '@/widget/google/tag-manager/google-tag-manager.store';
import { CalendarAvailabilityAwareness } from '@/widget/widget';

const bookingSummaryStore = useBookingSummaryStore();
const bookingItineraryStore = useBookingItineraryStore();
const searchStore = useSearchStore();
const elementsStore = useElementsStore();
const { trackUserEventAction } = useUserEventStore();
const { emitPixelPageViewEvent } = useFacebookPixelStore();
const { emitGAPageViewEvent } = useGoogleAnalyticsStore();
const { emitGTMPageViewEvent } = useGoogleTagManagerStore();

const availabilityCalendarStore = useProvideAvailabilityCalendarStore({
  property: computed(() => searchStore.activeProperty),
  showAllDatesAsAvailable:
    searchStore.widget.calendarAvailabilityAwareness ===
    CalendarAvailabilityAwareness.Unaware,
});

useProvideOccupancySelectorStore({
  property: computed(() => searchStore.activeProperty),
  unitOccupancies: mapOccupanciesToOccupancySelectorUnitOccupancies(
    searchStore.occupancies,
  ),
});

const preloadSearchPropertyAvailabilityCalendar = () => {
  availabilityCalendarStore.reset();

  const startDate = createDateObject(searchStore.stayDates.checkInDate);
  const endDate = addMonths(startDate, MONTHS_TO_ADD);

  void availabilityCalendarStore.load(
    format(startOfMonth(startDate), 'yyyy-MM-dd'),
    format(endOfMonth(endDate), 'yyyy-MM-dd'),
  );
};

watch(
  [
    () => bookingItineraryStore.hasEmptyUnitItinerary,
    () => searchStore.activePropertyAvailability,
  ],
  ([hasEmptyUnitItinerary, searchPropertyAvailability]) => {
    if (hasEmptyUnitItinerary) {
      bookingItineraryStore.propertyAvailability = searchPropertyAvailability;
      bookingItineraryStore.clearSelectedExtraItinerary();
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => searchStore.activeProperty,
  preloadSearchPropertyAvailabilityCalendar,
  {
    immediate: true,
  },
);

onActivated(() => {
  trackUserEventAction({
    label: UserEventActionLabel.PageView,
    metadata: {
      page_name: UNIT_SELECTION_ROUTE,
      widget_id: searchStore.widget.id,
      property_id: searchStore.activePropertyId,
      is_express_checkout: searchStore.activePropertyCanUseExpressCheckout,
      used_elements: elementsStore.usedElements,
      payment_gateway: searchStore.activeProperty.paymentGateway.type,
    },
  });

  if (searchStore.widget.googleTagManagerContainer) {
    emitGTMPageViewEvent(
      GoogleTagManagerPageViewEventAction.UnitSelectionPage,
      searchStore.activePropertyId,
      bookingItineraryStore.stayDates,
    );
  } else {
    void emitGAPageViewEvent(
      GoogleAnalyticsPageViewEventAction.UnitSelectionPage,
      searchStore.activeProperty.googleAnalytics,
      bookingItineraryStore.stayDates,
    );
    emitPixelPageViewEvent(
      FacebookPixelPageViewEventAction.UnitSelectionPage,
      searchStore.primaryProperty.facebookPixel,
      bookingItineraryStore.stayDates,
    );
  }
});
</script>
