<template>
  <AppLink
    :text="$t('remove')"
    class="justify-self-end text-sm capitalize text-gray-600"
    @click="onClick"
  />

  <BookingSummaryBodyUnitItemRemovingLastUnitWarningModal
    :unit-itinerary-item-id="unitItineraryItemId"
    :is-open="shouldShowWarning"
    @close="shouldShowWarning = false"
  />
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import BookingSummaryBodyUnitItemRemovingLastUnitWarningModal from '@/booking-summary/body/unit-item/remove-button/BookingSummaryBodyUnitItemRemovingLastUnitWarningModal.vue';
import AppLink from '@/ui/app-link/AppLink.vue';

const props = defineProps<{
  unitItineraryItemId: string;
}>();

const bookingItineraryStore = useBookingItineraryStore();
const bookingStageStore = useBookingStageStore();

const shouldShowWarning = ref(false);

const onClick = () => {
  if (
    bookingItineraryStore.unitItinerary.length === 1 &&
    bookingStageStore.currentStage.requiresNonEmptyUnitItinerary
  ) {
    shouldShowWarning.value = true;
  } else {
    bookingItineraryStore.removeUnitItineraryItemById(
      props.unitItineraryItemId,
    );
  }
};
</script>
