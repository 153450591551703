<template>
  <div v-if="isSmallerThanSm">
    <UnitSelectionSearchBarCodeInputButton
      :code="button.code"
      :show-code-error="button.showCodeError"
      @click="isCodeInputModalOpen = !isCodeInputModalOpen"
    />

    <AppModal
      :is-open="isCodeInputModalOpen"
      @close="isCodeInputModalOpen = false"
    >
      <AppModalDialog @close="isCodeInputModalOpen = false">
        <template #header>
          <CodeInputHeader />
        </template>

        <CodeInputBody
          :should-auto-focus-input="false"
          @close="isCodeInputModalOpen = false"
        />

        <template #footer>
          <CodeInputFooter
            @close="isCodeInputModalOpen = false"
            @clear="onClear"
          />
        </template>
      </AppModalDialog>
    </AppModal>
  </div>

  <UseElementBounding v-else v-slot="{ right }">
    <AppPopover v-slot="{ close }">
      <PopoverButton as="template">
        <UnitSelectionSearchBarCodeInputButton
          :code="button.code"
          :show-code-error="button.showCodeError"
        />
      </PopoverButton>

      <div
        class="absolute top-full"
        :class="
          isSmallerThanMd
            ? 'right-0 flex justify-center'
            : 'translate-x-[-100%]'
        "
        :style="{ left: `${isSmallerThanMd ? 0 : right}px` }"
      >
        <AppPopoverPanel>
          <template #header>
            <CodeInputHeader />
          </template>

          <CodeInputBody
            should-auto-focus-input
            class="w-96 px-6 py-4"
            @close="close"
          />

          <template #footer>
            <CodeInputFooter @close="close" @clear="onClear" />
          </template>
        </AppPopoverPanel>
      </div>
    </AppPopover>
  </UseElementBounding>
</template>

<script setup lang="ts">
import { PopoverButton } from '@headlessui/vue';
import { UseElementBounding } from '@vueuse/components';
import { useBreakpoints, breakpointsTailwind, useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { CodeInputStatusType } from '@/code/input/code-input-status';
import { useProvideCodeInputStore } from '@/code/input/code-input.store';
import CodeInputBody from '@/code/input/CodeInputBody.vue';
import CodeInputFooter from '@/code/input/CodeInputFooter.vue';
import CodeInputHeader from '@/code/input/CodeInputHeader.vue';
import { CodeResourceType } from '@/code/resource/code-resource';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { codeResourceRemoveEventBusKey } from '@/event-bus/event-bus';
import { useSearchStore } from '@/search/search.store';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import UnitSelectionSearchBarCodeInputButton from '@/unit-selection-page/search-bar/code-input/UnitSelectionSearchBarCodeInputButton.vue';

const searchStore = useSearchStore();
const codeResourceStore = useCodeResourceStore();

const { activeProperty, activePropertyAvailability, stayDates } =
  storeToRefs(searchStore);

const breakpoints = useBreakpoints(breakpointsTailwind);
const isSmallerThanMd = breakpoints.smaller('md');
const isSmallerThanSm = breakpoints.smaller('sm');

const isCodeInputModalOpen = ref(false);

const button = ref({ code: '', showCodeError: false });

const { code, status, removeCode } = useProvideCodeInputStore({
  property: activeProperty,
  stayDates,
  propertyAvailability: activePropertyAvailability,
});

if (codeResourceStore.resource) {
  code.value = codeResourceStore.resource.code;

  status.value =
    codeResourceStore.resource.type === CodeResourceType.Promocode
      ? {
          type: CodeInputStatusType.Valid,
          resource: {
            type: CodeResourceType.Promocode,
            promocode: codeResourceStore.resource.promocode,
          },
        }
      : {
          type: CodeInputStatusType.Valid,
          resource: {
            type: CodeResourceType.PrivateRateOverride,
            privateRateOverride: codeResourceStore.resource.privateRateOverride,
          },
        };
}

const onClear = () => {
  resetButton();
  codeResourceStore.clear();
};

const resetButton = () => {
  button.value = { code: '', showCodeError: false };
};

watch(
  status,
  (status, oldStatus) => {
    switch (status.type) {
      case CodeInputStatusType.Valid:
        if (status.resource.type === CodeResourceType.Promocode) {
          codeResourceStore.setWithPromocode(
            code.value,
            status.resource.promocode,
          );
        } else {
          codeResourceStore.setWithPrivateRateOverride(
            code.value,
            status.resource.privateRateOverride,
          );
        }

        button.value = { code: code.value, showCodeError: false };

        break;
      case CodeInputStatusType.Invalid:
        codeResourceStore.clear();

        if (oldStatus?.type === CodeInputStatusType.Valid) {
          button.value = { code: code.value, showCodeError: true };
        } else {
          resetButton();
        }

        break;
    }
  },
  { immediate: true },
);

useEventBus(codeResourceRemoveEventBusKey).on(() => {
  resetButton();
  removeCode();
});
</script>
