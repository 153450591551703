import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import BookingConfirmationView from '@/booking-confirmation-page/BookingConfirmationView.vue';
import BookingStageContainer from '@/booking-stage/BookingStageContainer.vue';
import PageNotFoundView from '@/error-pages/page-not-found/PageNotFoundView.vue';
import ExtrasView from '@/extras-page/ExtrasView.vue';
import GuestDetailsView from '@/guest-details-page/GuestDetailsView.vue';
import PaymentsView from '@/payments-page/PaymentsView.vue';
import PcibCardCaptureFailureView from '@/payments-page/pcib-card-capture/PcibCardCaptureFailureView.vue';
import PcibCardCaptureSuccessView from '@/payments-page/pcib-card-capture/PcibCardCaptureSuccessView.vue';
import {
  preservePropertyIdRouteParamNavigationGuard,
  preserveQueryParamsNavigationGuard,
} from '@/router/router.utilities';
import UnitSelectionView from '@/unit-selection-page/UnitSelectionView.vue';
import VanityViewContainer from '@/vanity-page/VanityViewContainer.vue';

export const VANITY_ROUTE = 'vanity';
export const UNIT_SELECTION_ROUTE = 'unit_selection';
export const EXTRAS_ROUTE = 'extras';
export const GUEST_DETAILS_ROUTE = 'guest_details';
export const PAYMENTS_ROUTE = 'payments';
export const BOOKING_CONFIRMATION_ROUTE = 'booking_confirmation';
export const DEMO_FEEDBACK_ROUTE = 'demo';
export const PCIB_CARD_CAPTURE_SUCCESS_ROUTE = 'pcib_card_capture_success';
export const PCIB_CARD_CAPTURE_FAILURE_ROUTE = 'pcib_card_capture_failure';
export const PAGE_NOT_FOUND_ROUTE = 'page_not_found';

const routes: RouteRecordRaw[] = [
  {
    name: VANITY_ROUTE,
    path: '/widgets/:widgetId/properties/:propertyId?',
    component: VanityViewContainer,
  },
  {
    path: '/widgets/:widgetId',
    component: BookingStageContainer,
    redirect: {
      name: UNIT_SELECTION_ROUTE,
    },
    children: [
      {
        name: UNIT_SELECTION_ROUTE,
        path: 'properties/:propertyId?/unit-selection',
        component: UnitSelectionView,
        beforeEnter: [
          preservePropertyIdRouteParamNavigationGuard,
          preserveQueryParamsNavigationGuard,
        ],
      },
      {
        name: EXTRAS_ROUTE,
        path: 'properties/:propertyId/extras',
        component: ExtrasView,
        beforeEnter: [preserveQueryParamsNavigationGuard],
      },
      {
        name: GUEST_DETAILS_ROUTE,
        path: 'properties/:propertyId/guest-details',
        component: GuestDetailsView,
        beforeEnter: [preserveQueryParamsNavigationGuard],
      },
      {
        name: PAYMENTS_ROUTE,
        path: 'properties/:propertyId/payments',
        component: PaymentsView,
        beforeEnter: [preserveQueryParamsNavigationGuard],
      },
    ],
    meta: { transitionCompletePromise: undefined },
  },
  {
    name: BOOKING_CONFIRMATION_ROUTE,
    path: '/booking/:bookingId',
    component: BookingConfirmationView,
  },
  {
    name: PCIB_CARD_CAPTURE_SUCCESS_ROUTE,
    path: '/pcib-card-capture-success',
    component: PcibCardCaptureSuccessView,
  },
  {
    name: PCIB_CARD_CAPTURE_FAILURE_ROUTE,
    path: '/pcib-card-capture-failure',
    component: PcibCardCaptureFailureView,
  },
  {
    name: PAGE_NOT_FOUND_ROUTE,
    path: '/:pathMatch(.*)*',
    component: PageNotFoundView,
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: async (to, from, savedPosition) => {
    if (to.name === from.name) {
      /**
       * Ensures scroll behavior is only invoked when changing between named routes,
       * and not, for example, when changing route/query parameters.
       */
      return;
    }

    // If necessary, delay the scroll to allow any associated transitions to finish first
    if (to.meta.transitionCompletePromise) {
      await to.meta.transitionCompletePromise;
    }

    return savedPosition ?? { top: 0 };
  },
});
