import { Decimal } from 'decimal.js';
import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { findPropertyContingencyChargeUnitById } from '@/contingency-charge/property/property-contingency-charge.utilities';
import { findPrivateRateOverrideIncludedPropertyMeals } from '@/private-rate-override/private-rate-override.utilities';
import type { MealType } from '@/property/meal/meal';
import { OfferType } from '@/property/offer/offer';
import { findPackageOfferIncludedPropertyMeals } from '@/property/offer/package-offer/package-offer.utilities';
import {
  findPropertyUnitById,
  findPropertyOfferById,
} from '@/property/property.utilities';
import {
  findUnitMeals,
  findUnitWayToSellById,
} from '@/property/unit/unit.utilities';
import type { MealRates } from '@/rates/meal-rates/meal-rates';
import { getTotalRateFromNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';
import { isNonPackageOfferRates } from '@/rates/offer-rates/offer-rates.utilities';
import type { UnitRateAdjustments } from '@/rates/unit-rate-adjustments/unit-rate-adjustments';
import { calculateUnitRateAdjustments } from '@/rates/unit-rate-adjustments/unit-rate-adjustments.utilities';

export const useUnitItineraryItemStore = (unitItineraryItemId: string) =>
  defineStore(`unit-itinerary-item-${unitItineraryItemId}`, () => {
    const codeResourceStore = useCodeResourceStore();
    const { propertyOverStay, stayDates, propertyAvailability, unitItinerary } =
      storeToRefs(useBookingItineraryStore());

    const unitItineraryItem = computed(
      () =>
        unitItinerary.value.find((item) => item.id === unitItineraryItemId)!,
    );

    const property = computed(() => propertyOverStay.value.property);

    const unit = computed(() => {
      const unit = findPropertyUnitById(
        property.value,
        unitItineraryItem.value.unitId,
      );

      if (!unit) {
        throw new Error(
          `Could not find unit ${unitItineraryItem.value.unitId} within property ${property.value.id}`,
        );
      }

      return unit;
    });

    const wayToSell = computed(() => {
      if (!unitItineraryItem.value.wayToSellId) {
        return;
      }

      const wayToSell = findUnitWayToSellById(
        unit.value,
        unitItineraryItem.value.wayToSellId,
      );

      if (!wayToSell) {
        throw new Error(
          `Could not find way to sell ${unitItineraryItem.value.wayToSellId} within unit ${unit.value.id}`,
        );
      }

      return wayToSell;
    });

    const offer = computed(() => {
      if (!unitItineraryItem.value.offerId) {
        return;
      }

      const offer = findPropertyOfferById(
        property.value,
        unitItineraryItem.value.offerId,
      );

      if (!offer) {
        throw new Error(
          `Could not find offer ${unitItineraryItem.value.offerId} within property ${property.value.id}`,
        );
      }

      return offer;
    });

    const isNonRefundable = computed(
      () =>
        !!offer.value &&
        offer.value.offerType !== OfferType.Package &&
        !offer.value.isRefundable,
    );

    const includedMeals = computed(() => {
      if (codeResourceStore.privateRateOverride) {
        return findPrivateRateOverrideIncludedPropertyMeals(
          codeResourceStore.privateRateOverride,
          property.value,
        );
      }

      if (offer.value?.offerType === OfferType.Package) {
        return findPackageOfferIncludedPropertyMeals(
          offer.value,
          property.value,
        );
      }

      return findUnitMeals(unit.value, propertyOverStay.value.includedMeals);
    });

    const supplementalMeals = computed(() =>
      offer.value?.offerType === OfferType.Package ||
      codeResourceStore.privateRateOverride
        ? []
        : findUnitMeals(unit.value, propertyOverStay.value.supplementalMeals),
    );

    const selectedMeals = computed(() =>
      supplementalMeals.value.filter(({ type }) =>
        unitItineraryItem.value.selectedMealTypes.includes(type),
      ),
    );

    const contingencyChargeUnit = computed(() =>
      propertyOverStay.value.property.contingencyCharge
        ? findPropertyContingencyChargeUnitById(
            propertyOverStay.value.property.contingencyCharge,
            unit.value.id,
          )
        : undefined,
    );

    const contingencyChargeAmount = computed(
      () => contingencyChargeUnit.value?.amount ?? 0,
    );

    const unitRateAdjustments = computed(
      (): UnitRateAdjustments =>
        calculateUnitRateAdjustments(
          unit.value,
          wayToSell.value,
          offer.value,
          codeResourceStore.promocode,
          codeResourceStore.privateRateOverride,
          supplementalMeals.value,
          propertyAvailability.value!,
          stayDates.value!,
          unitItineraryItem.value.occupancy,
        ),
    );

    const unitNightlyRates = computed(() => {
      if (unitRateAdjustments.value.standardPrivateRates) {
        return unitRateAdjustments.value.standardPrivateRates.nightlyRates;
      }

      if (
        unitRateAdjustments.value.offerNightlyRates &&
        isNonPackageOfferRates(unitRateAdjustments.value.offerNightlyRates) &&
        unitRateAdjustments.value.offerNightlyRates.promocodeRates
      ) {
        return unitRateAdjustments.value.offerNightlyRates.promocodeRates
          .nightlyRates;
      }

      if (unitRateAdjustments.value.offerNightlyRates) {
        return unitRateAdjustments.value.offerNightlyRates.nightlyRates;
      }

      if (unitRateAdjustments.value.standardPromocodeRates) {
        return unitRateAdjustments.value.standardPromocodeRates.nightlyRates;
      }

      return unitRateAdjustments.value.standardNightlyRates;
    });

    const mealsNightlyRates = computed(() =>
      selectedMeals.value.map(({ type }) => findMealRatesForMealType(type)),
    );

    const totalUnitRate = computed(() =>
      getTotalRateFromNightlyRates(unitNightlyRates.value),
    );

    const totalMealRate = computed(() =>
      mealsNightlyRates.value.reduce(
        (total, { nightlyRates }) =>
          total.add(getTotalRateFromNightlyRates(nightlyRates)),
        new Decimal(0),
      ),
    );

    const findMealRatesForMealType = (mealType: MealType): MealRates => {
      let mealRates = undefined;

      if (
        unitRateAdjustments.value.offerNightlyRates &&
        isNonPackageOfferRates(unitRateAdjustments.value.offerNightlyRates)
      ) {
        mealRates = unitRateAdjustments.value.offerNightlyRates.mealsRates.find(
          (mealRates) => mealRates.meal.type === mealType,
        );
      }

      return (
        mealRates ??
        unitRateAdjustments.value.standardMealsRates.find(
          (mealRates) => mealRates.meal.type === mealType,
        )!
      );
    };

    const getTotalRateForMealType = (mealType: MealType): number =>
      getTotalRateFromNightlyRates(
        findMealRatesForMealType(mealType).nightlyRates,
      );

    const totalRate = computed(() =>
      new Decimal(totalUnitRate.value).add(totalMealRate.value).toNumber(),
    );

    return {
      unit,
      wayToSell,
      offer,
      isNonRefundable,
      includedMeals,
      supplementalMeals,
      selectedMeals,
      contingencyChargeAmount,
      unitNightlyRates,
      mealsNightlyRates,
      totalUnitRate,
      totalRate,
      getTotalRateForMealType,
    };
  })();
