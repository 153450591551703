import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import type { Booking } from '@/booking/booking';
import {
  sendFetchBookingRequest,
  sendFetchMessagingLinkRequest,
  sendUpdateBookingRequest,
} from '@/booking/booking.api';
import { useBookingPolicyOverStay } from '@/property/booking-policy/over-stay/booking-policy-over-stay.composable';
import { useRoute } from '@/router/route.composable';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNumberOfDaysBetweenNowAndArrival } from '@/stay-dates/stay-dates.utilities';

export const useBookingConfirmationStore = defineStore(
  'booking-confirmation',
  () => {
    const {
      bookingIdRouteParam: bookingId,
      bookingAccessTokenQueryParam: bookingAccessToken,
    } = useRoute();

    const isInitialized = ref(false);

    const booking = ref<Booking>() as Ref<Booking>;

    const editableBooking = ref<Booking>() as Ref<Booking>;

    const property = computed(() => booking.value.property);

    const bookingPolicy = computed(() => booking.value.bookingPolicy);

    const allBookingUnits = computed(() => [
      ...booking.value.activeBookingUnits,
      ...booking.value.cancelledBookingUnits,
    ]);

    const hasMismatchedUnitStayDates = computed(() =>
      allBookingUnits.value.some(
        (bookingUnit) =>
          bookingUnit.checkInDate !== booking.value.checkInDate ||
          bookingUnit.checkOutDate !== booking.value.checkOutDate,
      ),
    );

    const bookingExtras = computed(() => booking.value.bookingExtras);

    const bookingGuest = computed(() => booking.value.bookingGuest);

    const promocode = computed(() => booking.value.promocode);

    const bookingPayments = computed(() => booking.value.bookingPayments);

    const bookingStayDates = computed<StayDates>(() => ({
      checkInDate: booking.value.checkInDate,
      checkOutDate: booking.value.checkOutDate,
    }));

    const bookingHasCheckedIn = computed(
      () =>
        getNumberOfDaysBetweenNowAndArrival(
          bookingStayDates.value,
          property.value.timezone,
        ) <= 0,
    );

    const bookingPolicyOverStay = useBookingPolicyOverStay(
      property,
      bookingPolicy,
      bookingStayDates,
    );

    const setBooking = (newBooking: Booking) => {
      booking.value = newBooking;

      editableBooking.value = cloneDeep(newBooking);
    };

    const initialize = async () => {
      if (isInitialized.value) {
        return;
      }

      const booking = await fetchBooking();

      setBooking(booking);

      isInitialized.value = true;
    };

    const fetchBooking = () =>
      sendFetchBookingRequest(bookingId.value, bookingAccessToken.value);

    const fetchMessagingLink = () =>
      sendFetchMessagingLinkRequest(bookingId.value, bookingAccessToken.value);

    const updateBooking = async () => {
      const booking = await sendUpdateBookingRequest(
        editableBooking.value,
        bookingAccessToken.value,
      );

      setBooking(booking);
    };

    return {
      isInitialized,
      booking,
      editableBooking,
      property,
      bookingPolicy,
      hasMismatchedUnitStayDates,
      bookingExtras,
      bookingGuest,
      promocode,
      bookingPayments,
      bookingStayDates,
      bookingHasCheckedIn,
      bookingPolicyOverStay,
      setBooking,
      initialize,
      fetchMessagingLink,
      updateBooking,
    };
  },
);
