<template>
  <AppFormValidatedInput
    v-slot="{ isValid }"
    :value="bookingItineraryStore.specialRequests"
    :validation-rules="[
      isLessThanOrEqualToLengthRule(
        SPECIAL_REQUESTS_MAX_LENGTH,
        $t('inputNameMustNotExceedMaxLengthCharacters', {
          inputName: capitalize($t('specialRequests')),
          maxLength: SPECIAL_REQUESTS_MAX_LENGTH,
        }),
      ),
    ]"
  >
    <AppTextarea
      v-model="bookingItineraryStore.specialRequests"
      :label="capitalize($t('doYouHaveAnySpecialRequests'))"
      :maximum-number-of-characters="SPECIAL_REQUESTS_MAX_LENGTH"
      :is-valid
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { SPECIAL_REQUESTS_MAX_LENGTH } from '@/booking/booking';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useLocaleString } from '@/string/locale-string.composable';
import { isLessThanOrEqualToLengthRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';
import AppTextarea from '@/ui/app-textarea/AppTextarea.vue';

const bookingItineraryStore = useBookingItineraryStore();

const { capitalize } = useLocaleString();
</script>
