import type { Placement } from '@floating-ui/vue';
import { flip, offset, arrow, useFloating, autoUpdate } from '@floating-ui/vue';
import { whenever } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, watchEffect } from 'vue';

export const useAppTooltipFloating = (
  tooltipAnchor: Ref<HTMLElement | null>,
  tooltip: Ref<HTMLElement | null>,
  placement: Placement,
  fallbackPlacements: Placement[],
  flipPadding?: Ref<number>,
): void => {
  const tooltipArrow = computed(() =>
    tooltip.value?.querySelector<HTMLElement>('#tooltipArrow'),
  );

  const {
    floatingStyles,
    placement: computedPlacement,
    middlewareData,
  } = useFloating(tooltipAnchor, tooltip, {
    placement,
    middleware: computed(() => [
      flip({
        fallbackPlacements,
        padding: flipPadding?.value ?? 0,
      }),
      offset(10),
      arrow({
        element: tooltipArrow,
        padding: 8,
      }),
    ]),
    whileElementsMounted: autoUpdate,
  });

  watchEffect(() => {
    if (tooltip.value) {
      Object.assign(tooltip.value.style, floatingStyles.value);
    }
  });

  whenever(
    () => middlewareData.value.arrow,
    ({ x, y }) => {
      if (!tooltipArrow.value) {
        return;
      }

      tooltipArrow.value.style.top = y !== undefined ? `${y}px` : '';
      tooltipArrow.value.style.left = x !== undefined ? `${x}px` : '';
      tooltipArrow.value.style[
        placementToCSSPosition(computedPlacement.value)
      ] = '-6px';
    },
  );
};

const placementToCSSPosition = (
  placement: Placement,
): Extract<keyof CSSStyleDeclaration, 'top' | 'right' | 'bottom' | 'left'> => {
  const staticPosition = placement.split('-')[0];

  switch (staticPosition) {
    case 'top':
      return 'bottom';
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    default:
      return 'top';
  }
};
