import type { BookingAdditionalTax } from '@/booking/booking-taxes/additional/booking-additional-tax';
import { createBookingAdditionalTax } from '@/booking/booking-taxes/additional/booking-additional-tax.utilities';
import type { BookingTaxes } from '@/booking/booking-taxes/booking-taxes';
import type { BookingVat } from '@/booking/booking-taxes/vat/booking-vat';
import type { Occupancy } from '@/occupancy/occupancy';
import type { PropertyTaxes } from '@/property/property-taxes/property-taxes';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { zipAllNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';
import {
  findFirstAdditionalTaxApplicableToDate,
  findAllAdditionalTaxesApplicableToDate,
} from '@/tax/additional/additional-tax.utilities';
import { AdditionalTaxRateInclusionType } from '@/tax/additional/rate/additional-tax-rate';

export const createBookingTaxes = (
  bookingVat: BookingVat | undefined,
  { cityTaxes, serviceCharges, otherTaxes }: PropertyTaxes,
  checkInDate: string,
  allUnitNightlyRates: NightlyRates[],
  allMealNightlyRates: NightlyRates[],
  numberOfUnits: number,
  occupancies: Occupancy[],
): BookingTaxes => {
  const unitNightlyRates = zipAllNightlyRates(allUnitNightlyRates);

  const unitAndMealNightlyRates = zipAllNightlyRates([
    ...allUnitNightlyRates,
    ...allMealNightlyRates,
  ]);

  const applicableCityTax = findFirstAdditionalTaxApplicableToDate(
    cityTaxes,
    checkInDate,
  );
  const applicableServiceCharge = findFirstAdditionalTaxApplicableToDate(
    serviceCharges,
    checkInDate,
  );
  const applicableOtherTaxes = findAllAdditionalTaxesApplicableToDate(
    otherTaxes,
    checkInDate,
  );

  return {
    bookingVat,
    bookingCityTax: applicableCityTax
      ? createBookingAdditionalTax(
          applicableCityTax,
          unitNightlyRates,
          numberOfUnits,
          occupancies,
        )
      : undefined,
    bookingServiceCharge: applicableServiceCharge
      ? createBookingAdditionalTax(
          applicableServiceCharge,
          unitAndMealNightlyRates,
          numberOfUnits,
          occupancies,
        )
      : undefined,
    bookingOtherTaxes: applicableOtherTaxes.map((applicableOtherTax) =>
      createBookingAdditionalTax(
        applicableOtherTax,
        unitAndMealNightlyRates,
        numberOfUnits,
        occupancies,
      ),
    ),
  };
};

export const hasAnyBookingTaxes = ({
  bookingVat,
  bookingCityTax,
  bookingServiceCharge,
  bookingOtherTaxes,
}: BookingTaxes): boolean =>
  !!bookingVat ||
  !!bookingCityTax ||
  !!bookingServiceCharge ||
  bookingOtherTaxes.length > 0;

export const groupBookingAdditionalTaxesByInclusionTypeFromBookingTaxes = (
  bookingTaxes: BookingTaxes,
): Record<AdditionalTaxRateInclusionType, BookingAdditionalTax[]> =>
  extractBookingAdditionalTaxesFromBookingTaxes(bookingTaxes).reduce<
    Record<AdditionalTaxRateInclusionType, BookingAdditionalTax[]>
  >(
    (groupedBookingAdditionalTaxes, bookingAdditionalTax) => {
      groupedBookingAdditionalTaxes[
        bookingAdditionalTax.tax.rate.inclusionType
      ].push(bookingAdditionalTax);

      return groupedBookingAdditionalTaxes;
    },
    {
      [AdditionalTaxRateInclusionType.Include]: [],
      [AdditionalTaxRateInclusionType.IncludeInvoiceOnly]: [],
      [AdditionalTaxRateInclusionType.Exclude]: [],
    },
  );

export const extractBookingAdditionalTaxesFromBookingTaxes = ({
  bookingCityTax,
  bookingServiceCharge,
  bookingOtherTaxes,
}: BookingTaxes): BookingAdditionalTax[] => [
  ...(bookingCityTax ? [bookingCityTax] : []),
  ...(bookingServiceCharge ? [bookingServiceCharge] : []),
  ...bookingOtherTaxes,
];
