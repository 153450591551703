import type {
  PropertyContingencyChargeUnit,
  PropertyContingencyChargeUnitDTO,
} from '@/contingency-charge/property/unit/property-contingency-charge-unit';

export const mapPropertyContingencyChargeUnitFromDTO = (
  propertyContingencyChargeUnitDTO: PropertyContingencyChargeUnitDTO,
): PropertyContingencyChargeUnit => ({
  unitId: propertyContingencyChargeUnitDTO.unitId,
  amount: Number(propertyContingencyChargeUnitDTO.amount),
});
