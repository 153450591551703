import type {
  ContingencyChargeTax,
  ContingencyChargeTaxDTO,
} from '@/contingency-charge/tax/contingency-charge-tax';

export interface ContingencyCharge {
  type: ContingencyChargeType;
  tax: ContingencyChargeTax | undefined;
}

export enum ContingencyChargeType {
  Security = 'security',
  Damage = 'damage',
}

export interface ContingencyChargeDTO extends Omit<ContingencyCharge, 'tax'> {
  tax: ContingencyChargeTaxDTO | null;
}
