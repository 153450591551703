<template>
  <AvailabilityCalendarModal
    :is-open="isModalOpen"
    :check-in-date="vanityStore.stayDates?.checkInDate ?? today.toISOString()"
    :check-out-date="
      vanityStore.stayDates?.checkOutDate ?? tomorrow.toISOString()
    "
    :auto-select-stay-dates="false"
    :button-text="capitalize($t('select'))"
    @close="isModalOpen = false"
    @submit="updateStayDates"
  />

  <div v-if="shouldUseModal">
    <VanitySearchOptionButton :icon="IconCalendar" @click="isModalOpen = true">
      <VanitySearchAvailabilityCalendarButtonContent />
    </VanitySearchOptionButton>
  </div>

  <VanitySearchAvailabilityCalendarPopover v-else @submit="updateStayDates" />
</template>
<script setup lang="ts">
import { IconCalendar } from '@tabler/icons-vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { addMonths, startOfMonth, endOfMonth, addDays } from 'date-fns';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import {
  MONTHS_TO_ADD,
  useProvideAvailabilityCalendarStore,
} from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarModal from '@/availability-calendar/modal/AvailabilityCalendarModal.vue';
import {
  formatDateObject,
  getCurrentDateObjectInTimezone,
} from '@/date/date.utilities';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import VanitySearchAvailabilityCalendarButtonContent from '@/vanity-page/search/availability-calendar/VanitySearchAvailabilityCalendarButtonContent.vue';
import VanitySearchAvailabilityCalendarPopover from '@/vanity-page/search/availability-calendar/VanitySearchAvailabilityCalendarPopover.vue';
import VanitySearchOptionButton from '@/vanity-page/search/button/VanitySearchOptionButton.vue';
import { useVanityStore } from '@/vanity-page/vanity.store';
import { CalendarAvailabilityAwareness } from '@/widget/widget';

const searchStore = useSearchStore();
const vanityStore = useVanityStore();

const shouldUseModal = useBreakpoints(breakpointsTailwind).smaller('md');
const { capitalize } = useLocaleString();

const { activeProperty } = storeToRefs(searchStore);

const isModalOpen = ref(false);

const today = getCurrentDateObjectInTimezone(
  searchStore.activeProperty.timezone,
);
const tomorrow = addDays(today, 1);

const availabilityCalendarStore = useProvideAvailabilityCalendarStore({
  property: activeProperty,
  showAllDatesAsAvailable:
    searchStore.widget.calendarAvailabilityAwareness ===
    CalendarAvailabilityAwareness.Unaware,
});

const preloadPropertyAvailabilityCalendar = () => {
  availabilityCalendarStore.reset();

  const endDate = addMonths(today, MONTHS_TO_ADD);

  void availabilityCalendarStore.load(
    formatDateObject(startOfMonth(today)),
    formatDateObject(endOfMonth(endDate)),
  );
};

onMounted(preloadPropertyAvailabilityCalendar);

const updateStayDates = async () => {
  isModalOpen.value = false;

  const updatedStayDates = availabilityCalendarStore.selectedStayDates.value;

  if (updatedStayDates) {
    vanityStore.stayDates = updatedStayDates;

    await searchStore.updateStayDates(updatedStayDates);
  }
};
</script>
