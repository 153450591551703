<template>
  <div class="w-full overflow-y-auto overflow-x-hidden">
    <BookingSummaryBodyContainer
      :should-slide-items-out="!bookingItineraryStore.hasEmptyUnitItinerary"
    >
      <li
        v-if="bookingItineraryStore.hasEmptyUnitItinerary"
        class="flex justify-center bg-white text-neutral-500"
      >
        <p
          v-if="bookingItineraryStore.propertyOverStay.isAvailable"
          class="first-letter:capitalize"
        >
          {{
            $t('pleaseSelectAUnitToContinue', { unitTypeName, unitTypeGender })
          }}
        </p>

        <p v-else class="first-letter:capitalize">
          {{ $t('pleaseFindAvailableDatesToContinue') }}
        </p>
      </li>

      <li v-else class="text-lg font-semibold capitalize">
        {{ unitTypeNamePlural }}
      </li>

      <li
        v-for="(item, index) in bookingItineraryStore.unitItinerary"
        :key="item.id"
        class="mt-4"
        :class="{ 'border-t border-neutral-200 pt-4': index > 0 }"
      >
        <BookingSummaryBodyUnitItem
          :unit-itinerary-item="item"
          :unit-number="index + 1"
          :show-room-number="bookingItineraryStore.unitItinerary.length > 1"
        />
      </li>

      <template v-if="canShowExtras">
        <li key="extraHeading" class="mt-6 text-lg font-semibold capitalize">
          {{ $t('extras') }}
        </li>

        <li
          v-for="extraItineraryItemStore in bookingItineraryStore.mandatoryExtraItinerary"
          :key="extraItineraryItemStore.extra.id"
          data-test="BookingSummaryBodyExtraItem"
          class="mt-4"
        >
          <BookingSummaryBodyExtraItem :extra="extraItineraryItemStore.extra" />
        </li>

        <li
          v-for="extraItineraryItemStore in bookingItineraryStore.selectedExtraItinerary"
          :key="extraItineraryItemStore.extra.id"
          data-test="BookingSummaryBodyExtraItem"
          class="mt-4"
        >
          <BookingSummaryBodyExtraItem :extra="extraItineraryItemStore.extra" />
        </li>
      </template>

      <BookingSummaryBodyCodeResource
        v-if="
          codeResourceStore.resource &&
          !bookingItineraryStore.hasEmptyUnitItinerary
        "
        class="mt-6"
        :code-resource="codeResourceStore.resource"
        @remove="onCodeResourceRemove"
      />

      <template v-if="canShowTaxes">
        <li key="taxesHeading" class="mt-6 text-lg font-semibold capitalize">
          {{ $t('taxes') }}
        </li>

        <li
          v-if="
            bookingItineraryStore.bookingTaxes.bookingVat &&
            bookingItineraryStore.bookingTaxes.bookingVat.includedAmount > 0
          "
          key="includedVat"
          class="mt-2"
        >
          <BookingSummaryBodyIncludedVat
            :booking-vat="bookingItineraryStore.bookingTaxes.bookingVat"
          />
        </li>

        <li
          v-for="(bookingAdditionalTax, index) of orderedBookingAdditionalTaxes"
          :key="index"
          class="mt-2"
        >
          <BookingSummaryBodyAdditionalTax
            :booking-additional-tax="bookingAdditionalTax"
          />
        </li>

        <li
          v-if="
            bookingItineraryStore.bookingTaxes.bookingVat &&
            bookingItineraryStore.bookingTaxes.bookingVat.excludedAmount > 0
          "
          key="excludedVat"
          class="mt-2"
        >
          <BookingSummaryBodyExcludedVat
            :booking-vat="bookingItineraryStore.bookingTaxes.bookingVat"
          />
        </li>
      </template>

      <li
        v-if="bookingStageStore.currentStage.shouldDisplayGuestDetailsInSummary"
      >
        <BookingSummaryBodyGuestDetails />
      </li>
    </BookingSummaryBodyContainer>
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { computed } from 'vue';
import {
  groupBookingAdditionalTaxesByInclusionTypeFromBookingTaxes,
  hasAnyBookingTaxes,
} from '@/booking/booking-taxes/booking-taxes.utilities';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import BookingSummaryBodyCodeResource from '@/booking-summary/body/code-resource/BookingSummaryBodyCodeResource.vue';
import BookingSummaryBodyExtraItem from '@/booking-summary/body/extra-item/BookingSummaryBodyExtraItem.vue';
import BookingSummaryBodyGuestDetails from '@/booking-summary/body/guest-details/BookingSummaryBodyGuestDetails.vue';
import BookingSummaryBodyAdditionalTax from '@/booking-summary/body/taxes/BookingSummaryBodyAdditionalTax.vue';
import BookingSummaryBodyExcludedVat from '@/booking-summary/body/taxes/BookingSummaryBodyExcludedVat.vue';
import BookingSummaryBodyIncludedVat from '@/booking-summary/body/taxes/BookingSummaryBodyIncludedVat.vue';
import BookingSummaryBodyUnitItem from '@/booking-summary/body/unit-item/BookingSummaryBodyUnitItem.vue';
import BookingSummaryBodyContainer from '@/booking-summary/BookingSummaryBodyContainer.vue';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { codeResourceRemoveEventBusKey } from '@/event-bus/event-bus';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useSearchStore } from '@/search/search.store';
import { AdditionalTaxRateInclusionType } from '@/tax/additional/rate/additional-tax-rate';

const codeResourceStore = useCodeResourceStore();
const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();
const bookingStageStore = useBookingStageStore();

const { unitTypeName, unitTypeGender, unitTypeNamePlural } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);

const canShowExtras = computed(
  () =>
    (bookingItineraryStore.selectedExtraItinerary.length > 0 ||
      bookingItineraryStore.mandatoryExtraItinerary.length > 0) &&
    !bookingItineraryStore.hasEmptyUnitItinerary,
);

const canShowTaxes = computed(
  () =>
    hasAnyBookingTaxes(bookingItineraryStore.bookingTaxes) &&
    !bookingItineraryStore.hasEmptyUnitItinerary,
);

const groupedBookingAdditionalTaxes = computed(() =>
  groupBookingAdditionalTaxesByInclusionTypeFromBookingTaxes(
    bookingItineraryStore.bookingTaxes,
  ),
);

const orderedBookingAdditionalTaxes = computed(() => [
  ...groupedBookingAdditionalTaxes.value[
    AdditionalTaxRateInclusionType.Include
  ],
  ...groupedBookingAdditionalTaxes.value[
    AdditionalTaxRateInclusionType.Exclude
  ],
  ...groupedBookingAdditionalTaxes.value[
    AdditionalTaxRateInclusionType.IncludeInvoiceOnly
  ],
]);

const onCodeResourceRemove = () => {
  codeResourceStore.clear();

  useEventBus(codeResourceRemoveEventBusKey).emit();
};
</script>
