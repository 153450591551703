import type { Address, AddressDTO } from '@/address/address';
import type {
  PropertyContingencyCharge,
  PropertyContingencyChargeDTO,
} from '@/contingency-charge/property/property-contingency-charge';
import type { Image, ImageDTO } from '@/image/image';
import type { Occupancy, OccupancyDTO } from '@/occupancy/occupancy';
import type {
  BookingPolicies,
  BookingPoliciesDTO,
} from '@/property/booking-policy/booking-policies/booking-policies';
import type {
  CheckInPolicy,
  CheckInPolicyDTO,
} from '@/property/check-in-policy/check-in-policy';
import type {
  ChildPolicy,
  ChildPolicyDTO,
} from '@/property/child-policy/child-policy';
import type { Extra, ExtraDTO } from '@/property/extra/extra';
import type {
  FacebookPixel,
  FacebookPixelDTO,
} from '@/property/facebook-pixel/facebook-pixel';
import type {
  GoogleAdwords,
  GoogleAdwordsDTO,
} from '@/property/google/adwords/google-adwords';
import type {
  GoogleAnalytics,
  GoogleAnalyticsDTO,
} from '@/property/google/analytics/google-analytics';
import type {
  GroupsPolicy,
  GroupsPolicyDTO,
} from '@/property/groups-policy/groups-policy';
import type {
  LateBookingThresholds,
  LateBookingThresholdsDTO,
} from '@/property/late-booking-threshold/late-booking-thresholds';
import type { Meal, MealDTO } from '@/property/meal/meal';
import type { Offer, OfferDTO } from '@/property/offer/offer';
import type {
  ParkingPolicies,
  ParkingPoliciesDTO,
} from '@/property/parking-policy/parking-policies';
import type {
  PaymentGateway,
  PaymentGatewayDTO,
} from '@/property/payment-gateway/payment-gateway';
import type {
  PetsPolicy,
  PetsPolicyDTO,
} from '@/property/pets-policy/pets-policy';
import type {
  PropertyTaxes,
  PropertyTaxesDTO,
} from '@/property/property-taxes/property-taxes';
import type {
  PropertyTheme,
  PropertyThemeDTO,
} from '@/property/property-theme/property-theme';
import type {
  PropertyVanity,
  PropertyVanityDTO,
} from '@/property/property-vanity/property-vanity';
import type {
  PropertyWidgetSettings,
  PropertyWidgetSettingsDTO,
} from '@/property/property-widget-settings/property-widget-settings';
import type {
  SmokingPolicy,
  SmokingPolicyDTO,
} from '@/property/smoking-policy/smoking-policy';
import type { UnitType } from '@/property/unit/type/unit-type';
import type { Unit, UnitDTO } from '@/property/unit/unit';

export const MAX_INFANT_AGE = 2;

export enum UnitOrderPreference {
  BestFitOccupancyLowestPrice = 'best_price_plan',
  BestFitOccupancyHighestPrice = 'best_fit_occupancy_highest_price',
  UnitPriority = 'unit_priority',
}

export interface Property {
  id: number;
  name: string;
  unitType: UnitType;
  address: Address;
  phoneNumber: string;
  emailAddress: string;
  currencyCode: string;
  timezone: string;
  units: Unit[];
  priorityOrderedUnitIds: number[];
  meals: Meal[];
  offers: Offer[];
  hasAnyPromocodes: boolean;
  hasAnyPrivateRateOverrides: boolean;
  extras: Extra[];
  priorityOrderedExtraIds: number[];
  images: Image[];
  mainImageId: number | undefined;
  contingencyCharge: PropertyContingencyCharge | undefined;
  childPolicy: ChildPolicy;
  bookingPolicies: BookingPolicies;
  smokingPolicy: SmokingPolicy;
  checkInPolicy: CheckInPolicy;
  parkingPolicies: ParkingPolicies;
  groupsPolicy: GroupsPolicy;
  petsPolicy: PetsPolicy;
  propertyTaxes: PropertyTaxes;
  propertyTheme: PropertyTheme;
  propertyVanity: PropertyVanity | undefined;
  paymentGateway: PaymentGateway;
  widgetSettings: PropertyWidgetSettings;
  defaultSearchOccupancy: Occupancy;
  unitOrderPreference: UnitOrderPreference;
  maxStayLength: number;
  googleAnalytics: GoogleAnalytics | undefined;
  googleAdwords: GoogleAdwords | undefined;
  facebookPixel: FacebookPixel | undefined;
  extendedTermsAndConditions: string;
  lateBookingThresholds: LateBookingThresholds;
  canUseExpressCheckout: boolean;
}

export interface PropertyDTO
  extends Omit<
    Property,
    | 'unitType'
    | 'address'
    | 'units'
    | 'meals'
    | 'offers'
    | 'extras'
    | 'images'
    | 'mainImageId'
    | 'contingencyCharge'
    | 'childPolicy'
    | 'bookingPolicies'
    | 'smokingPolicy'
    | 'checkInPolicy'
    | 'parkingPolicies'
    | 'groupsPolicy'
    | 'petsPolicy'
    | 'propertyTaxes'
    | 'propertyTheme'
    | 'propertyVanity'
    | 'paymentGateway'
    | 'widgetSettings'
    | 'defaultSearchOccupancy'
    | 'googleAnalytics'
    | 'googleAdwords'
    | 'facebookPixel'
    | 'lateBookingThresholds'
    | 'canUseExpressCheckout'
  > {
  unitTypeName: string;
  address: AddressDTO;
  units: UnitDTO[];
  meals: MealDTO[];
  offers: OfferDTO[];
  extras: ExtraDTO[];
  images: ImageDTO[];
  mainImageId: number | null;
  contingencyCharge: PropertyContingencyChargeDTO | null;
  childPolicy: ChildPolicyDTO;
  bookingPolicies: BookingPoliciesDTO;
  smokingPolicy: SmokingPolicyDTO;
  checkInPolicy: CheckInPolicyDTO;
  parkingPolicies: ParkingPoliciesDTO;
  groupsPolicy: GroupsPolicyDTO;
  petsPolicy: PetsPolicyDTO;
  taxes: PropertyTaxesDTO;
  propertyTheme: PropertyThemeDTO;
  propertyVanity: PropertyVanityDTO | null;
  paymentGateway: PaymentGatewayDTO;
  widgetSettings: PropertyWidgetSettingsDTO;
  defaultSearchOccupancy: OccupancyDTO;
  googleAnalytics: GoogleAnalyticsDTO | null;
  googleAdwords: GoogleAdwordsDTO | null;
  facebookPixel: FacebookPixelDTO | null;
  lateBookingThresholds: LateBookingThresholdsDTO;
  expressCheckout: object | null;
}
