import { isEmpty } from 'lodash-es';
import type { NavigationGuardWithThis } from 'vue-router';

export const preservePropertyIdRouteParamNavigationGuard: NavigationGuardWithThis<
  undefined
> = (to, from) => {
  if (!to.params['propertyId'] && from.params['propertyId']) {
    return {
      ...to,
      params: {
        ...to.params,
        propertyId: from.params['propertyId'],
      },
    };
  }

  return true;
};

export const preserveQueryParamsNavigationGuard: NavigationGuardWithThis<
  undefined
> = (to, from) => {
  if (isEmpty(to.query) && !isEmpty(from.query)) {
    return {
      ...to,
      query: from.query,
    };
  }

  return true;
};
