<template>
  <div v-if="isUpdatingSelectedMeals" class="flex gap-2">
    <AppButton
      intent="secondary"
      class="px-4 py-1"
      :text="toTitleCase($t('cancel'))"
      @click="isUpdatingSelectedMeals = false"
    />

    <AppButton
      intent="primary"
      class="px-4 py-1"
      :text="toTitleCase($t('save'))"
      @click="updateSelectedMeals"
    />
  </div>

  <BookingSummaryBodyUnitItemUpdateSelectedMeal
    v-else-if="arrayContainSingleItem(supplementalMeals)"
    :meal="supplementalMeals[0]"
  />

  <AppLink
    v-else
    class="text-sm"
    :text="toTitleCase($t(hasSelectedAnyMeals ? 'changeMeals' : 'addMeals'))"
    @click="isUpdatingSelectedMeals = true"
  />
</template>

<script setup lang="ts">
import { arrayContainSingleItem } from '@/array/array.utilities';
import { useBookingSummaryMealsStore } from '@/booking-summary/body/unit-item/meals/booking-summary-meals.store';
import BookingSummaryBodyUnitItemUpdateSelectedMeal from '@/booking-summary/body/unit-item/meals/BookingSummaryBodyUnitItemUpdateSelectedMeal.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppLink from '@/ui/app-link/AppLink.vue';

const { toTitleCase } = useLocaleString();

const {
  isUpdatingSelectedMeals,
  supplementalMeals,
  hasSelectedAnyMeals,
  updateSelectedMeals,
} = useBookingSummaryMealsStore();
</script>
