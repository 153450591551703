<template>
  <AppModal :is-open="isOpen" @close="$emit('close')">
    <AppModalDialog
      class="relative md:w-auto"
      body-class="p-0"
      @close="$emit('close')"
    >
      <template v-if="isSmallerThanMd" #header>
        <AvailabilityCalendarHeader />
      </template>

      <AvailabilityCalendarContainer
        :check-in-date="checkInDate"
        :check-out-date="checkOutDate"
        :auto-select-stay-dates="autoSelectStayDates"
      />

      <template #footer>
        <AvailabilityCalendarFooter
          :icon="icon"
          :button-text="buttonText"
          @submit="$emit('submit')"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import type { Icon } from '@tabler/icons-vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import AvailabilityCalendarHeader from '@/availability-calendar/AvailabilityCalendarHeader.vue';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';

const { autoSelectStayDates = true } = defineProps<{
  isOpen: boolean;
  checkInDate: string;
  checkOutDate: string;
  autoSelectStayDates?: boolean;
  buttonText: string;
  icon?: Icon | undefined;
}>();

defineEmits(['submit', 'close']);

const breakpoints = useBreakpoints(breakpointsTailwind);

const isSmallerThanMd = breakpoints.smaller('md');
</script>
