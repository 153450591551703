import { Decimal } from 'decimal.js';
import { partition } from 'lodash-es';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { findCardByCardType } from '@/card/card.utilities';
import { usePaymentMethodName } from '@/payment-method/name/payment-method-name.composable';
import { PaymentMethodType } from '@/payment-method/payment-method';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { CardPaymentMethodPolicyCardCharge } from '@/property/booking-policy/payment-method-policy/card/charge/card-payment-method-policy-card-charge';
import { CardPaymentMethodPolicyCardChargeType } from '@/property/booking-policy/payment-method-policy/card/charge/card-payment-method-policy-card-charge';
import type { Property } from '@/property/property';

export const usePaymentMethodPoliciesTermsAndConditionsDisplayText = (
  bookingPolicy: Ref<BookingPolicy>,
  property: Ref<Property>,
) => {
  const { t, n, locale } = useI18n();

  const listFormatter = computed(
    () =>
      new Intl.ListFormat(locale.value, {
        style: 'long',
        type: 'conjunction',
      }),
  );

  const paymentMethodPolicies = computed(
    () => bookingPolicy.value.paymentMethodPolicies,
  );

  const paymentMethodPoliciesPartitionedByCardPaymentMethodPolicy = computed(
    () =>
      partition(
        paymentMethodPolicies.value,
        (paymentMethodPolicy) =>
          paymentMethodPolicy.paymentMethodType === PaymentMethodType.Card,
      ),
  );

  const cardPaymentMethodPolicy = computed(
    () => paymentMethodPoliciesPartitionedByCardPaymentMethodPolicy.value[0][0],
  );

  const nonCardPaymentMethodPolicies = computed(
    () => paymentMethodPoliciesPartitionedByCardPaymentMethodPolicy.value[1],
  );

  const cardPaymentMethodCardsDisplayTexts = computed(
    () =>
      cardPaymentMethodPolicy.value?.cards.reduce<string[]>(
        (displayTexts, { cardType, charge }) => {
          const { name, isMain } = findCardByCardType(cardType);

          if (isMain) {
            const chargeDisplayText = generateCardChargeDisplayText(charge);

            displayTexts.push(
              chargeDisplayText ? `${name} (${chargeDisplayText})` : name,
            );
          }

          return displayTexts;
        },
        [],
      ) ?? [],
  );

  const cardPaymentMethodCardsDisplayText = computed(() =>
    listFormatter.value.format(cardPaymentMethodCardsDisplayTexts.value),
  );

  const cardPaymentMethodDisplayText = computed(() =>
    cardPaymentMethodCardsDisplayText.value
      ? t('theFollowingCards', {
          cards: cardPaymentMethodCardsDisplayText.value,
        })
      : '',
  );

  const nonCardPaymentMethodsDisplayTexts = computed(() =>
    nonCardPaymentMethodPolicies.value.map(({ paymentMethodType }) => {
      const paymentMethodName = usePaymentMethodName(paymentMethodType);

      return paymentMethodName.value;
    }),
  );

  const paymentMethodsDisplayTexts = computed(() => {
    const displayTexts = [...nonCardPaymentMethodsDisplayTexts.value];

    if (cardPaymentMethodDisplayText.value) {
      displayTexts.push(cardPaymentMethodDisplayText.value);
    }

    return displayTexts;
  });

  const paymentMethodsDisplayText = computed(() =>
    listFormatter.value.format(paymentMethodsDisplayTexts.value),
  );

  const generateCardChargeDisplayText = (
    charge: CardPaymentMethodPolicyCardCharge,
  ): string => {
    switch (charge.chargeType) {
      case CardPaymentMethodPolicyCardChargeType.Monetary:
        return t('aChargeOfAmountApplies', {
          amount: n(charge.amount, {
            style: 'currency',
            currency: property.value.currencyCode,
          }),
        });
      case CardPaymentMethodPolicyCardChargeType.Percentage:
        return t('aChargeOfAmountApplies', {
          amount: n(
            new Decimal(charge.percentage).dividedBy(100).toNumber(),
            'percent',
          ),
        });
    }

    return '';
  };

  return computed(() =>
    paymentMethodsDisplayText.value
      ? t('paymentsBy', {
          paymentMethods: paymentMethodsDisplayText.value,
        })
      : '',
  );
};
