<template>
  <div class="flex items-center justify-between space-x-4">
    <AppButton
      intent="secondary"
      class="w-full"
      :text="toTitleCase($t('doNotChange'))"
      @click="$emit('close')"
    />

    <AppButton
      intent="primary"
      class="w-full"
      :text="toTitleCase($t('change'))"
      @click="((bookingItineraryStore.unitItinerary = []), $emit('close'))"
    />
  </div>
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';

const { toTitleCase } = useLocaleString();

const bookingItineraryStore = useBookingItineraryStore();

defineEmits(['close']);
</script>
