import {
  ContingencyChargeType,
  type ContingencyCharge,
} from '@/contingency-charge/contingency-charge';

export const getContingencyChargeNameTranslationKey = ({
  type,
}: ContingencyCharge): string => {
  switch (type) {
    case ContingencyChargeType.Security:
      return 'securityDeposit';
    case ContingencyChargeType.Damage:
      return 'damageDeposit';
  }
};
