<template>
  <AppAnimatedHeightContainer>
    <div
      :id="panelId"
      ref="accordionPanel"
      :aria-labelledby="buttonId"
      :class="{ 'h-0': !isOpen }"
      :inert="!isOpen"
    >
      <slot></slot>
    </div>
  </AppAnimatedHeightContainer>
</template>

<script setup lang="ts">
import { useAccordionState } from '@/ui/app-accordion/app-accordion.composable';
import AppAnimatedHeightContainer from '@/ui/app-animated-height-container/AppAnimatedHeightContainer.vue';

const { isOpen, buttonId, panelId } = useAccordionState();
</script>
