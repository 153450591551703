<template>
  <AppTextarea
    v-if="extra.question"
    v-model.trim="answer"
    :label="extra.question"
    label-class="break-anywhere mb-1 font-semibold text-zinc-600"
    should-translate-label
    :maximum-number-of-characters="128"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import type { Extra } from '@/property/extra/extra';
import AppTextarea from '@/ui/app-textarea/AppTextarea.vue';

const props = defineProps<{
  extra: Extra;
}>();

const extraItineraryItemStore = props.extra.isMandatory
  ? useMandatoryExtraItineraryItemStore(props.extra)
  : useExtraItineraryItemStore(props.extra);

const answer = computed({
  get() {
    return extraItineraryItemStore.answer ?? '';
  },
  set(newAnswer) {
    extraItineraryItemStore.answer = newAnswer;
  },
});
</script>
