<template>
  <i18n-t keypath="termsAndConditionsBookingAgreement" tag="p" scope="global">
    <template #linkText>
      <AppLink
        :text="$t('termsAndConditionsBookingAgreementLinkText')"
        type="button"
        @click="isModalOpen = true"
      />

      <BookingItineraryTermsAndConditionsStatementModal
        :is-open="isModalOpen"
        @close="isModalOpen = false"
      />
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import BookingItineraryTermsAndConditionsStatementModal from '@/booking-itinerary/terms-and-conditions/statement/modal/BookingItineraryTermsAndConditionsStatementModal.vue';
import AppLink from '@/ui/app-link/AppLink.vue';

const isModalOpen = ref(false);
</script>
