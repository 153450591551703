<template>
  <UnitSelectionNoAvailabilityButtonContainer v-if="shouldUseModal">
    <AppButton
      intent="primary"
      class="w-full px-6 py-2"
      :text="$t('tryAlternativeDates')"
      :icon="IconSearch"
      @click="
        unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen =
          !unitSelectionStore.isSearchPropertyAvailabilityCalendarModalOpen
      "
    />
  </UnitSelectionNoAvailabilityButtonContainer>

  <AppPopover v-else v-slot="{ close }">
    <UnitSelectionNoAvailabilityButtonContainer ref="popoverButton">
      <AppPopoverButton
        class="min-w-full"
        :as="AppButton"
        intent="primary"
        :text="$t('tryAlternativeDates')"
        :icon="IconSearch"
      />
    </UnitSelectionNoAvailabilityButtonContainer>

    <div
      ref="popoverPanel"
      class="z-50 scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.unit-selection-search-bar-height))]"
      :style="floatingStyles"
    >
      <AppPopoverPanel>
        <AvailabilityCalendarContainer
          :check-in-date="searchStore.stayDates.checkInDate"
          :check-out-date="searchStore.stayDates.checkOutDate"
          :auto-select-stay-dates="autoSelectStayDates"
          @vue:mounted="scrollPopoverPanelIntoView"
        />

        <template #footer>
          <AvailabilityCalendarFooter
            :button-text="capitalize($t('search'))"
            :icon="IconSearch"
            @submit="
              () => {
                close();
                updateSearchDates();
              }
            "
          />
        </template>
      </AppPopoverPanel>
    </div>
  </AppPopover>
</template>
<script setup lang="ts">
import { useFloating, autoUpdate, shift } from '@floating-ui/vue';
import { IconSearch } from '@tabler/icons-vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { useTemplateRef } from 'vue';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import UnitSelectionNoAvailabilityButtonContainer from '@/unit-selection-page/no-availability/UnitSelectionNoAvailabilityButtonContainer.vue';
import { useUnitSelectionSearchPropertyAvailabilityCalendar } from '@/unit-selection-page/search-property-availability-calendar/unit-selection-search-property-availability-calendar.composable';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();

const shouldUseModal = useBreakpoints(breakpointsTailwind).smaller('md');
const { capitalize } = useLocaleString();

const popoverButton = useTemplateRef('popoverButton');
const popoverPanel = useTemplateRef<HTMLElement>('popoverPanel');

const scrollPopoverPanelIntoView = () => {
  setTimeout(() => {
    popoverPanel.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  });
};

const { floatingStyles } = useFloating(popoverButton, popoverPanel, {
  placement: 'bottom',
  middleware: [shift({ padding: 15 })],
  whileElementsMounted: autoUpdate,
});

const { autoSelectStayDates, updateSearchDates } =
  useUnitSelectionSearchPropertyAvailabilityCalendar();
</script>
