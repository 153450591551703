<template>
  <button
    v-if="isCondensed"
    :aria-label="labelText"
    class="hover:saturate-150 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:saturate-150"
    @click="onClick"
  >
    <TheFooterRegionFlag :region-code="regionCode" />
  </button>

  <AppLink
    v-else
    class="group"
    :underlined="false"
    :text="languageName"
    :label="labelText"
    @click="onClick"
  >
    <template #icon>
      <TheFooterRegionFlag
        class="group-hover:saturate-150 group-focus-visible:saturate-150"
        :region-code="regionCode"
      />
    </template>
  </AppLink>

  <LocaleSelectorModal
    :is-open="isLocaleSelectorModalOpen"
    @close="isLocaleSelectorModalOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TheFooterRegionFlag from '@/footer/TheFooterRegionFlag.vue';
import {
  getRegionCodeOfLocale,
  getDisplayNameOfLocaleLanguage,
} from '@/intl/intl.utilities';
import LocaleSelectorModal from '@/locale-selector/LocaleSelectorModal.vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLink from '@/ui/app-link/AppLink.vue';

defineProps<{ isCondensed: boolean }>();

const { locale, t } = useI18n();
const { toTitleCase } = useLocaleString();

const isLocaleSelectorModalOpen = ref(false);

const regionCode = computed(() => getRegionCodeOfLocale(locale.value));

const labelText = computed(
  () => `${toTitleCase(t('selectLanguage'))}: ${languageName.value}`,
);

const languageName = computed(() =>
  toTitleCase(
    getDisplayNameOfLocaleLanguage(locale.value, {
      displayNameOptions: {
        languageDisplay: 'standard',
        style: 'long',
      },
    }),
  ),
);

const onClick = () => {
  isLocaleSelectorModalOpen.value = true;
};
</script>
