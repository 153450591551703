<template>
  <AppModal class="z-30" :is-open="isModalOpen">
    <AppModalDialog class="sm:max-w-2xl" :should-show-close-button="false">
      <template #header>
        <span class="align-middle font-semibold leading-none">
          {{ $t('selectionNoLongerAvailable') }}
        </span>
      </template>

      <div>
        {{ $t('sorryWeCouldNotConfirmThisBooking') }}
        {{ $t('thisIsLikelyBecauseThePriceOrAvailabilityHasChanged') }}
        <br /><br />
        {{
          $t('pleaseGoBackToUnitTypeNameSelectionAndTryAgain', { unitTypeName })
        }}
      </div>

      <template #footer>
        <AppButton
          class="w-full"
          intent="primary"
          :text="$t('goToUnitTypeNameSelection', { unitTypeName })"
          @click="goToUnitSelection"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { bookingCreateAvailabilityErrorEventBusKey } from '@/event-bus/event-bus';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import router, { UNIT_SELECTION_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';

const searchStore = useSearchStore();
const bookingItineraryStore = useBookingItineraryStore();

const isModalOpen = ref(false);

const unitTypeName = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
).unitTypeName.value;

useEventBus(bookingCreateAvailabilityErrorEventBusKey).on(() => {
  isModalOpen.value = true;
});

const goToUnitSelection = () => {
  bookingItineraryStore.reset();

  isModalOpen.value = false;

  void searchStore.refreshPropertyAvailabilitiesWithLoadingFlag();

  void router.replace({
    name: UNIT_SELECTION_ROUTE,
  });
};
</script>
