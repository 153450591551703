<template>
  <ContainerLayout class="flex grow flex-col">
    <BookingStageViewHeader class="mx-2 mt-10" :label="$t('paymentDetails')" />

    <div class="mx-3 mt-6 flex grow space-x-5">
      <div class="flex-[2]">
        <PaymentsPaymentConditions
          :payment-gateway="paymentGateway"
          class="mb-6"
        />

        <StripePayment
          v-if="paymentGateway.type === PaymentGatewayType.Stripe"
          :stripe-payment-gateway="paymentGateway"
        />

        <PcibCardCapture
          v-else-if="paymentGateway.type === PaymentGatewayType.Pcib"
        />

        <SagePayCheckout
          v-else-if="paymentGateway.type === PaymentGatewayType.SagePay"
          :sage-pay-payment-gateway="paymentGateway"
        />

        <BookingItineraryTermsAndConditionsStatementAccordion
          v-if="!searchStore.activePropertyCanUseExpressCheckout"
          class="mt-6 xl:max-w-lg"
        />
      </div>

      <div v-if="!bookingSummaryStore.showModalVersion" class="flex-1">
        <div
          class="sticky top-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.4))]"
        >
          <InlineBookingSummary />
        </div>
      </div>
    </div>
  </ContainerLayout>
</template>

<script setup lang="ts">
import { computed, onActivated } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingItineraryTermsAndConditionsStatementAccordion from '@/booking-itinerary/terms-and-conditions/statement/accordion/BookingItineraryTermsAndConditionsStatementAccordion.vue';
import BookingStageViewHeader from '@/booking-stage/view-header/BookingStageViewHeader.vue';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import InlineBookingSummary from '@/booking-summary/InlineBookingSummary.vue';
import { useElementsStore } from '@/elements/elements.store';
import ContainerLayout from '@/layouts/ContainerLayout.vue';
import PaymentsPaymentConditions from '@/payments-page/PaymentsPaymentConditions.vue';
import PcibCardCapture from '@/payments-page/pcib-card-capture/PcibCardCapture.vue';
import SagePayCheckout from '@/payments-page/sage-pay-checkout/SagePayCheckout.vue';
import StripePayment from '@/payments-page/stripe-payment/StripePayment.vue';
import { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { PAYMENTS_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';
import { GoogleTagManagerPageViewEventAction } from '@/widget/google/tag-manager/google-tag-manager-event-action';
import { useGoogleTagManagerStore } from '@/widget/google/tag-manager/google-tag-manager.store';

const bookingSummaryStore = useBookingSummaryStore();
const bookingItineraryStore = useBookingItineraryStore();
const searchStore = useSearchStore();
const elementsStore = useElementsStore();
const { trackUserEventAction } = useUserEventStore();
const { emitGAPageViewEvent } = useGoogleAnalyticsStore();
const { emitGTMPageViewEvent } = useGoogleTagManagerStore();
const { emitPixelPageViewEvent } = useFacebookPixelStore();

onActivated(() => {
  trackUserEventAction({
    label: UserEventActionLabel.PageView,
    metadata: {
      page_name: PAYMENTS_ROUTE,
      widget_id: searchStore.widget.id,
      property_id: searchStore.activePropertyId,
      is_express_checkout: searchStore.activePropertyCanUseExpressCheckout,
      used_elements: elementsStore.usedElements,
      payment_gateway: searchStore.activeProperty.paymentGateway.type,
    },
  });

  if (searchStore.widget.googleTagManagerContainer) {
    emitGTMPageViewEvent(
      GoogleTagManagerPageViewEventAction.PaymentsPage,
      searchStore.activePropertyId,
      bookingItineraryStore.stayDates,
    );
  } else {
    void emitGAPageViewEvent(
      GoogleAnalyticsPageViewEventAction.PaymentsPage,
      searchStore.activeProperty.googleAnalytics,
      bookingItineraryStore.stayDates,
    );
    emitPixelPageViewEvent(
      FacebookPixelPageViewEventAction.PaymentsPage,
      searchStore.primaryProperty.facebookPixel,
      bookingItineraryStore.stayDates,
    );
  }
});

const paymentGateway = computed(
  () => searchStore.activeProperty.paymentGateway,
);
</script>
