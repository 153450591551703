import type {
  BookingUnitContingencyCharge,
  BookingUnitContingencyChargeDTO,
} from '@/contingency-charge/booking-unit/booking-unit-contingency-charge';
import { mapContingencyChargeTaxFromDTO } from '@/contingency-charge/tax/contingency-charge-tax.mapper';

export const mapBookingUnitContingencyChargeFromDTO = (
  contingencyChargeDTO: BookingUnitContingencyChargeDTO,
): BookingUnitContingencyCharge => ({
  amount: Number(contingencyChargeDTO.amount),
  type: contingencyChargeDTO.type,
  tax: contingencyChargeDTO.tax
    ? mapContingencyChargeTaxFromDTO(contingencyChargeDTO.tax)
    : undefined,
});
