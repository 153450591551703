import type {
  BookingOffer,
  BookingOfferDTO,
} from '@/booking/offer/booking-offer';
import type { BookingUnit } from '@/booking/unit/booking-unit';
import type {
  BookingUnitMeal,
  BookingUnitMealDTO,
} from '@/booking/unit/meal/booking-unit-meal';
import type {
  BookingWayToSell,
  BookingWayToSellDTO,
} from '@/booking/way-to-sell/booking-way-to-sell';
import type {
  BookingUnitContingencyCharge,
  BookingUnitContingencyChargeDTO,
} from '@/contingency-charge/booking-unit/booking-unit-contingency-charge';
import type { Occupancy, OccupancyDTO } from '@/occupancy/occupancy';

export enum TwinDoubleBedOption {
  Twin = 'twin',
  Double = 'double',
}

export interface ActiveBookingUnit extends BookingUnit {
  rate: number;
  bookingUnitWayToSell: BookingWayToSell | undefined;
  bookingUnitOffer: BookingOffer | undefined;
  occupancy: Occupancy;
  bookingUnitMeals: BookingUnitMeal[];
  twinDoubleBedOption: TwinDoubleBedOption | undefined;
  leadGuestName: string | undefined;
  isNonRefundable: boolean;
  contingencyCharge: BookingUnitContingencyCharge | undefined;
}

export interface ActiveBookingUnitDTO
  extends Omit<
    ActiveBookingUnit,
    | 'rate'
    | 'bookingUnitWayToSell'
    | 'bookingUnitOffer'
    | 'occupancy'
    | 'bookingUnitMeals'
    | 'twinDoubleBedOption'
    | 'leadGuestName'
    | 'contingencyCharge'
  > {
  rate: string;
  bookingUnitWayToSell: BookingWayToSellDTO | null;
  bookingUnitOffer: BookingOfferDTO | null;
  occupancy: OccupancyDTO;
  bookingUnitMeals: BookingUnitMealDTO[];
  twinDoubleBedOption: TwinDoubleBedOption | null;
  leadGuestName: string | null;
  contingencyCharge: BookingUnitContingencyChargeDTO | null;
}
