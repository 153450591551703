import type {
  PropertyContingencyCharge,
  PropertyContingencyChargeDTO,
} from '@/contingency-charge/property/property-contingency-charge';
import { mapPropertyContingencyChargeUnitFromDTO } from '@/contingency-charge/property/unit/property-contingency-charge-unit.mapper';
import { mapContingencyChargeTaxFromDTO } from '@/contingency-charge/tax/contingency-charge-tax.mapper';

export const mapPropertyContingencyChargeFromDTO = (
  contingencyChargeDTO: PropertyContingencyChargeDTO,
): PropertyContingencyCharge => ({
  units: contingencyChargeDTO.units.map(
    mapPropertyContingencyChargeUnitFromDTO,
  ),
  type: contingencyChargeDTO.type,
  isIncludedInDeposit: contingencyChargeDTO.isIncludedInDeposit,
  tax: contingencyChargeDTO.tax
    ? mapContingencyChargeTaxFromDTO(contingencyChargeDTO.tax)
    : undefined,
});
