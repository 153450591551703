import { Decimal } from 'decimal.js';
import { defineStore } from 'pinia';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import { fetchPropertyAvailability } from '@/availability/property-availability/property-availability.api';
import { getPropertyAvailabilityStayDates } from '@/availability/property-availability/property-availability.utilities';
import {
  createBookingTaxes,
  extractBookingAdditionalTaxesFromBookingTaxes,
} from '@/booking/booking-taxes/booking-taxes.utilities';
import type { BookingVat } from '@/booking/booking-taxes/vat/booking-vat';
import type {
  SerializableBookingItinerary,
  SerializableMandatoryExtraItineraryItem,
  UnitItineraryItem,
} from '@/booking-itinerary/booking-itinerary';
import {
  findLastIndexOfUnitItineraryItem,
  isUnitItineraryAvailable,
} from '@/booking-itinerary/booking-itinerary.utilities';
import type { MandatoryExtraItineraryItem } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item';
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import { useSelectableNightExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/selectable-night-extra-itinerary-item.store';
import { useUnitItineraryItemStore } from '@/booking-itinerary/unit-itinerary-item/store/unit-itinerary-item.store';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { generateUniqueId } from '@/generator/generator.utilities';
import i18n from '@/i18n';
import { calculateDepositPolicyChargeAmount } from '@/property/booking-policy/deposit-policy/charge/deposit-policy-charge.utilities';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import { ExtraChargeType } from '@/property/extra/extra';
import { isExtraApplicableOnStayDates } from '@/property/extra/extra.utilities';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { usePropertyOverStay } from '@/property/property-over-stay/property-over-stay.composable';
import {
  findPropertyMandatoryExtraByIdOrFail,
  findPropertyNonMandatoryExtraByIdOrFail,
  getPropertyNonMandatoryExtras,
  propertyHasPaymentGateway,
} from '@/property/property.utilities';
import type { NightlyRates } from '@/rates/nightly-rates/nightly-rates';
import { zipAllNightlyRates } from '@/rates/nightly-rates/nightly-rates.utilities';
import router, { UNIT_SELECTION_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import { AdditionalTaxRateInclusionType } from '@/tax/additional/rate/additional-tax-rate';
import type { TaxableItem } from '@/tax/taxable-item/taxable-item';
import { calculateVatAmountOfTaxableItems } from '@/tax/taxable-item/taxable-item.utilities';
import type { Time } from '@/time/time';
import { useQueryParams } from '@/url/query-params/query-params.composable';

const { t } = i18n.global;

export const useBookingItineraryStore = defineStore('booking-itinerary', () => {
  const searchStore = useSearchStore();
  const codeResourceStore = useCodeResourceStore();

  const { bookingItineraryQueryParam } = useQueryParams();

  const propertyAvailability = ref<PropertyAvailability>();

  const unitItinerary = ref<UnitItineraryItem[]>([]);

  const specialRequests = ref('');

  const checkInTime = ref<Time>();

  const optionalExtraItineraryItems = computed(() =>
    getPropertyNonMandatoryExtras(searchStore.activeProperty).map((extra) =>
      useExtraItineraryItemStore({
        ...extra,
        isMandatory: extra.isMandatory,
        chargeType: extra.chargeType,
      }),
    ),
  );

  const selectedExtraItinerary = computed(() =>
    optionalExtraItineraryItems.value.filter(
      (extraItineraryItem) => extraItineraryItem.quantity > 0,
    ),
  );

  const mandatoryExtraItinerary = computed(() =>
    searchStore.activeProperty.extras.reduce(
      (mandatoryExtraItinerary: MandatoryExtraItineraryItem[], extra) => {
        if (
          extra.isMandatory &&
          isExtraApplicableOnStayDates(extra, stayDates.value!)
        ) {
          mandatoryExtraItinerary.push(
            useMandatoryExtraItineraryItemStore(extra),
          );
        }

        return mandatoryExtraItinerary;
      },
      [],
    ),
  );

  const numberOfUnits = computed(() => unitItinerary.value.length);

  const occupancies = computed(() =>
    unitItinerary.value.map(({ occupancy }) => occupancy),
  );

  const unitItineraryItems = computed(() =>
    unitItinerary.value.map(({ id }) => useUnitItineraryItemStore(id)),
  );

  const extraItineraryItems = computed(() => [
    ...selectedExtraItinerary.value,
    ...mandatoryExtraItinerary.value,
  ]);

  const allUnitNightlyRates: ComputedRef<NightlyRates[]> = computed(() =>
    unitItineraryItems.value.map(({ unitNightlyRates }) => unitNightlyRates),
  );

  const allMealNightlyRates: ComputedRef<NightlyRates[]> = computed(() =>
    unitItineraryItems.value.flatMap(({ mealsNightlyRates }) =>
      mealsNightlyRates.map(({ nightlyRates }) => nightlyRates),
    ),
  );

  const bookingVat = computed<BookingVat | undefined>(() => {
    const { vat } = searchStore.activeProperty.propertyTaxes;

    if (!vat) {
      return undefined;
    }

    const contingencyChargeTax =
      searchStore.activeProperty.contingencyCharge?.tax;

    const { included, excluded } = calculateVatAmountOfTaxableItems([
      {
        isIncluded: true,
        taxPercentage: vat.percentage,
        taxableAmount: unitItineraryTotalRate.value,
      },
      ...(contingencyChargeTax
        ? [
            {
              isIncluded: contingencyChargeTax.isIncluded,
              taxPercentage: contingencyChargeTax.percentage,
              taxableAmount: contingencyChargeTotalAmount.value,
            },
          ]
        : []),
      ...extraItineraryItems.value.reduce<TaxableItem[]>(
        (taxableExtras, { extra: { tax }, totalRate }) => {
          if (tax) {
            taxableExtras.push({
              isIncluded: tax.isIncluded,
              taxPercentage: tax.percentage,
              taxableAmount: totalRate,
            });
          }

          return taxableExtras;
        },
        [],
      ),
    ]);

    return {
      vat,
      includedAmount: included,
      excludedAmount: excluded,
    };
  });

  const bookingTaxes = computed(() =>
    createBookingTaxes(
      bookingVat.value,
      searchStore.activeProperty.propertyTaxes,
      stayDates.value!.checkInDate,
      allUnitNightlyRates.value,
      allMealNightlyRates.value,
      numberOfUnits.value,
      occupancies.value,
    ),
  );

  const stayDates = computed(() =>
    propertyAvailability.value
      ? getPropertyAvailabilityStayDates(propertyAvailability.value)
      : undefined,
  );

  const propertyOverStay = usePropertyOverStay(
    computed(() => searchStore.activeProperty),
    propertyAvailability as Ref<PropertyAvailability>,
    computed(() => codeResourceStore.resource),
  );

  const hasEmptyUnitItinerary = computed(() => numberOfUnits.value === 0);

  const isAnyUnitItineraryItemNonRefundable: ComputedRef<boolean> = computed(
    () =>
      unitItineraryItems.value.some(({ isNonRefundable }) => isNonRefundable),
  );

  const hasEmptySelectedExtraItinerary = computed(
    () => selectedExtraItinerary.value.length === 0,
  );

  const serializableBookingItinerary = computed<
    SerializableBookingItinerary | undefined
  >(() =>
    !hasEmptyUnitItinerary.value
      ? {
          checkInDate: stayDates.value!.checkInDate,
          checkOutDate: stayDates.value!.checkOutDate,
          checkInTime: checkInTime.value,
          specialRequests: specialRequests.value,
          unitItinerary: unitItinerary.value.map((unitItineraryItem) => ({
            unitId: unitItineraryItem.unitId,
            wayToSellId: unitItineraryItem.wayToSellId,
            offerId: unitItineraryItem.offerId,
            occupancy: unitItineraryItem.occupancy,
            selectedMealTypes: unitItineraryItem.selectedMealTypes,
            leadGuestName: unitItineraryItem.leadGuestName,
            twinDoubleBedOption: unitItineraryItem.twinDoubleBedOption,
          })),
          selectedExtraItinerary: selectedExtraItinerary.value.map(
            (extraItineraryItem) => ({
              id: extraItineraryItem.extra.id,
              quantity: extraItineraryItem.quantity,
              answer: extraItineraryItem.answer,
              numberOfNightsSelected:
                extraItineraryItem.extra.chargeType ===
                ExtraChargeType.SelectNightsOfStay
                  ? useSelectableNightExtraItineraryItemStore(
                      extraItineraryItem.extra,
                    ).numberOfNightsApplied
                  : undefined,
            }),
          ),
          mandatoryExtraItinerary: mandatoryExtraItinerary.value.reduce<
            SerializableMandatoryExtraItineraryItem[]
          >((extraAnswers, extraItineraryItem) => {
            const extraAnswer = extraItineraryItem.answer;

            if (extraAnswer !== undefined) {
              extraAnswers.push({
                id: extraItineraryItem.extra.id,
                answer: extraAnswer,
              });
            }

            return extraAnswers;
          }, []),
        }
      : undefined,
  );

  const hasPaymentGateway = computed(() =>
    propertyHasPaymentGateway(searchStore.activeProperty),
  );

  const totalRateBeforeTax = computed(() => calculateTotalRateBeforeTax(true));

  const totalRate = computed(() =>
    Decimal.add(totalRateBeforeTax.value, taxAmount.value).toNumber(),
  );

  const taxAmount = computed(() => {
    const excludedAdditionalTaxAmount =
      extractBookingAdditionalTaxesFromBookingTaxes(bookingTaxes.value)
        .filter(
          ({
            tax: {
              rate: { inclusionType },
            },
          }) =>
            inclusionType === AdditionalTaxRateInclusionType.IncludeInvoiceOnly,
        )
        .reduce((total, { amount }) => total.add(amount), new Decimal(0));

    return Decimal.add(
      excludedAdditionalTaxAmount,
      bookingTaxes.value.bookingVat?.excludedAmount ?? 0,
    ).toNumber();
  });

  const shouldDepositIncludeTaxes = computed(
    () =>
      searchStore.activeProperty.paymentGateway.type !==
        PaymentGatewayType.Stripe ||
      searchStore.activeProperty.paymentGateway.shouldDepositIncludeTaxes,
  );

  const canTakeDeposit = computed(
    () =>
      searchStore.activeProperty.paymentGateway.type !==
        PaymentGatewayType.SagePay ||
      searchStore.activeProperty.paymentGateway.canTakeDeposits,
  );

  const unitItineraryTotalRate: ComputedRef<number> = computed(() =>
    unitItineraryItems.value
      .reduce((total, { totalRate }) => total.add(totalRate), new Decimal(0))
      .toNumber(),
  );

  const extraItineraryTotalRate = computed(() =>
    extraItineraryItems.value
      .reduce((total, { totalRate }) => total.add(totalRate), new Decimal(0))
      .toNumber(),
  );

  const contingencyChargeTotalAmount: ComputedRef<number> = computed(() =>
    unitItineraryItems.value
      .reduce(
        (total, { contingencyChargeAmount }) =>
          total.add(contingencyChargeAmount),
        new Decimal(0),
      )
      .toNumber(),
  );

  const depositBasisAmount = computed(() =>
    calculateTotalRateBeforeTax(
      !!propertyOverStay.property.contingencyCharge?.isIncludedInDeposit,
    ),
  );

  const depositAmount = computed(() => {
    let depositAmount = 0;

    const { depositPolicy } =
      propertyOverStay.bookingPolicyOverStay.bookingPolicy;

    if (!canTakeDeposit.value) {
      depositAmount = 0;
    } else if (isAnyUnitItineraryItemNonRefundable.value) {
      depositAmount = depositBasisAmount.value;
    } else if (depositPolicy.policyType === DepositPolicyType.No) {
      depositAmount = 0;
    } else {
      depositAmount = calculateDepositPolicyChargeAmount(
        depositPolicy.charge,
        depositBasisAmount.value,
        zipAllNightlyRates([
          ...allUnitNightlyRates.value,
          ...allMealNightlyRates.value,
        ]),
        numberOfUnits.value,
      );
    }

    if (depositAmount > 0 && shouldDepositIncludeTaxes.value) {
      depositAmount = Decimal.add(depositAmount, taxAmount.value).toNumber();
    }

    return depositAmount;
  });

  const reset = () => {
    unitItinerary.value = [];
    clearSelectedExtraItinerary();
    codeResourceStore.resource = undefined;
    specialRequests.value = '';
    checkInTime.value = undefined;
  };

  const addUnitItineraryItem = (unitItineraryItem: UnitItineraryItem): void => {
    unitItinerary.value.push(unitItineraryItem);
  };

  const removeUnitItineraryItem = (
    unitItineraryItem: UnitItineraryItem,
  ): void => {
    const indexToRemove = findLastIndexOfUnitItineraryItem(
      unitItineraryItem,
      unitItinerary.value,
    );

    if (indexToRemove !== -1) {
      unitItinerary.value.splice(indexToRemove, 1);
    }
  };

  const removeUnitItineraryItemById = (unitItineraryItemId: string): void => {
    unitItinerary.value = unitItinerary.value.filter(
      (item) => item.id !== unitItineraryItemId,
    );
  };

  const validateItineraryAvailability = async () => {
    await validateUnitItineraryAvailability();
    validateExtraItineraryAvailability();
  };

  const validateUnitItineraryAvailability = async () => {
    const unitItineraryIsAvailable = isUnitItineraryAvailable(
      unitItinerary.value,
      propertyOverStay,
    );

    if (!unitItineraryIsAvailable) {
      unitItinerary.value = [];

      void searchStore.refreshPropertyAvailabilitiesWithLoadingFlag();

      await router.replace({
        name: UNIT_SELECTION_ROUTE,
      });

      toast.info(t('sorrySomethingYouSelectedIsNoLongerAvailable'), {
        autoClose: false,
      });
    }
  };

  const validateExtraItineraryAvailability = () => {
    const extraItineraryIsAvailable = selectedExtraItinerary.value.every(
      (extraItineraryItem) =>
        isExtraApplicableOnStayDates(
          extraItineraryItem.extra,
          stayDates.value!,
        ),
    );

    if (!extraItineraryIsAvailable) {
      clearSelectedExtraItinerary();
    }
  };

  const clearSelectedExtraItinerary = () => {
    selectedExtraItinerary.value.forEach(
      (extraItineraryItem) => (extraItineraryItem.quantity = 0),
    );
  };

  const clearSelectedUnitItinerary = () => {
    unitItinerary.value = [];
  };

  const initialize = async () => {
    if (!bookingItineraryQueryParam.value) {
      return;
    }

    await loadPropertyAvailability(
      searchStore.activePropertyId,
      bookingItineraryQueryParam.value.checkInDate,
      bookingItineraryQueryParam.value.checkOutDate,
    );

    checkInTime.value = bookingItineraryQueryParam.value.checkInTime;

    specialRequests.value =
      bookingItineraryQueryParam.value.specialRequests ?? '';

    unitItinerary.value = bookingItineraryQueryParam.value.unitItinerary.map(
      (serializableUnitItineraryItem) => ({
        id: generateUniqueId(),
        unitId: serializableUnitItineraryItem.unitId,
        wayToSellId: serializableUnitItineraryItem.wayToSellId,
        offerId: serializableUnitItineraryItem.offerId,
        occupancy: serializableUnitItineraryItem.occupancy,
        selectedMealTypes: serializableUnitItineraryItem.selectedMealTypes,
        leadGuestName: serializableUnitItineraryItem.leadGuestName,
        twinDoubleBedOption: serializableUnitItineraryItem.twinDoubleBedOption,
      }),
    );

    recoverExtraItineraryFromBookingItineraryQueryParam();
  };

  const recoverExtraItineraryFromBookingItineraryQueryParam = () => {
    recoverSelectedExtraItineraryFromBookingItineraryQueryParam();
    recoverMandatoryExtraItineraryFromBookingItineraryQueryParam();
  };

  const recoverSelectedExtraItineraryFromBookingItineraryQueryParam = () => {
    for (const item of bookingItineraryQueryParam.value!
      .selectedExtraItinerary) {
      const extra = findPropertyNonMandatoryExtraByIdOrFail(
        searchStore.activeProperty,
        item.id,
      );

      const extraItineraryItem = useExtraItineraryItemStore(extra);
      extraItineraryItem.quantity = item.quantity;
      extraItineraryItem.answer = item.answer;

      if (
        item.numberOfNightsSelected &&
        extra.chargeType === ExtraChargeType.SelectNightsOfStay
      ) {
        useSelectableNightExtraItineraryItemStore({
          ...extra,
          chargeType: extra.chargeType,
        }).numberOfNightsApplied = item.numberOfNightsSelected;
      }
    }
  };

  const recoverMandatoryExtraItineraryFromBookingItineraryQueryParam = () => {
    for (const extraAnswer of bookingItineraryQueryParam.value!
      .mandatoryExtraItinerary) {
      const extra = findPropertyMandatoryExtraByIdOrFail(
        searchStore.activeProperty,
        extraAnswer.id,
      );

      useMandatoryExtraItineraryItemStore(extra).answer = extraAnswer.answer;
    }
  };

  const loadPropertyAvailability = async (
    propertyId: number,
    fromDate: string,
    toDate: string,
  ) => {
    propertyAvailability.value = await fetchPropertyAvailability(propertyId, {
      fromDate,
      toDate,
    });
  };

  const refreshPropertyAvailability = async () => {
    await loadPropertyAvailability(
      searchStore.activeProperty.id,
      stayDates.value!.checkInDate,
      stayDates.value!.checkOutDate,
    );

    await validateItineraryAvailability();
  };

  const syncBookingItineraryToQueryParams = () => {
    watch(
      serializableBookingItinerary,
      (serializableBookingItinerary) => {
        bookingItineraryQueryParam.value = serializableBookingItinerary;
      },
      {
        deep: true,
        immediate: true,
      },
    );
  };

  const calculateTotalRateBeforeTax = (
    shouldIncludeContingencyCharges: boolean,
  ): number =>
    hasEmptyUnitItinerary.value
      ? 0
      : new Decimal(unitItineraryTotalRate.value)
          .add(extraItineraryTotalRate.value)
          .add(
            shouldIncludeContingencyCharges
              ? contingencyChargeTotalAmount.value
              : 0,
          )
          .toNumber();

  return {
    propertyAvailability,
    propertyOverStay,
    stayDates,
    unitItinerary,
    specialRequests,
    checkInTime,
    bookingTaxes,
    hasEmptyUnitItinerary,
    hasEmptySelectedExtraItinerary,
    isAnyUnitItineraryItemNonRefundable,
    serializableBookingItinerary,
    hasPaymentGateway,
    totalRateBeforeTax,
    totalRate,
    depositAmount,
    mandatoryExtraItinerary,
    selectedExtraItinerary,
    taxAmount,
    clearSelectedUnitItinerary,
    clearSelectedExtraItinerary,
    reset,
    addUnitItineraryItem,
    removeUnitItineraryItem,
    removeUnitItineraryItemById,
    refreshPropertyAvailability,
    initialize,
    validateItineraryAvailability,
    syncBookingItineraryToQueryParams,
  };
});
