import { useConfirmDialog } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import { useElementsStore } from '@/elements/elements.store';
import type { Occupancy } from '@/occupancy/occupancy';
import type { PricePlan } from '@/price-plan/price-plan';
import { usePropertyPricePlans } from '@/price-plan/property-price-plans/property-price-plans.composable';
import { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import type { Meal } from '@/property/meal/meal';
import type { Property } from '@/property/property';
import { UNIT_SELECTION_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import type { StayDates } from '@/stay-dates/stay-dates';
import { useUnitSelectionUnitScroller } from '@/unit-selection-page/unit/unit-selection-unit-scroller.composable';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';
import { GoogleTagManagerPageViewEventAction } from '@/widget/google/tag-manager/google-tag-manager-event-action';
import { useGoogleTagManagerStore } from '@/widget/google/tag-manager/google-tag-manager.store';

export const useUnitSelectionStore = defineStore('unit-selection', () => {
  const { trackUserEventAction } = useUserEventStore();
  const { emitGAPageViewEvent } = useGoogleAnalyticsStore();
  const { emitGTMPageViewEvent } = useGoogleTagManagerStore();
  const { emitPixelPageViewEvent } = useFacebookPixelStore();

  const bookingStageStore = useBookingStageStore();
  const searchStore = useSearchStore();
  const bookingItineraryStore = useBookingItineraryStore();
  const elementsStore = useElementsStore();

  const isSearchPropertyAvailabilityCalendarModalOpen = ref(false);

  const pricePlanSelectedMeals = ref(new Map<PricePlan['id'], Meal[]>());

  const pricePlans = computed(() =>
    usePropertyPricePlans(
      searchStore.activePropertyOverStay,
      searchStore.occupancies,
    ),
  );

  const stayDateMismatchWarningModal = useConfirmDialog<
    never,
    boolean,
    never
  >();

  const updateProperty = async (property: Property, stayDates?: StayDates) => {
    bookingItineraryStore.reset();

    await searchStore.updateActiveProperty(property.id, stayDates);

    scrollToTop();

    trackUserEventAction({
      label: UserEventActionLabel.PageView,
      metadata: {
        page_name: UNIT_SELECTION_ROUTE,
        widget_id: searchStore.widget.id,
        property_id: property.id,
        is_express_checkout: searchStore.activePropertyCanUseExpressCheckout,
        used_elements: elementsStore.usedElements,
        payment_gateway: property.paymentGateway.type,
      },
    });

    if (searchStore.widget.googleTagManagerContainer) {
      emitGTMPageViewEvent(
        GoogleTagManagerPageViewEventAction.UnitSelectionPage,
        property.id,
        bookingItineraryStore.stayDates,
      );
    } else {
      void emitGAPageViewEvent(
        GoogleAnalyticsPageViewEventAction.UnitSelectionPage,
        property.googleAnalytics,
        bookingItineraryStore.stayDates,
      );

      emitPixelPageViewEvent(
        FacebookPixelPageViewEventAction.UnitSelectionPage,
        searchStore.primaryProperty.facebookPixel,
        bookingItineraryStore.stayDates,
      );
    }
  };

  const updateStayDates = async (
    stayDates: StayDates,
    unitIdToScrollTo?: number,
  ) => {
    await searchStore.updateStayDates(stayDates);

    scrollUnitIntoView(unitIdToScrollTo);
  };

  const updateOccupancies = async (
    occupancies: Occupancy[],
    unitIdToScrollTo?: number,
  ) => {
    await searchStore.updateOccupancies(occupancies);

    scrollUnitIntoView(unitIdToScrollTo);
  };

  const scrollUnitIntoView = (unitIdToScrollTo?: number) => {
    const { scrollUnitIntoView, scrollFirstUnitIntoView } =
      useUnitSelectionUnitScroller();

    if (unitIdToScrollTo) {
      scrollUnitIntoView(unitIdToScrollTo);
    } else {
      scrollFirstUnitIntoView();
    }
  };

  const scrollToTop = () => {
    setTimeout(() => {
      scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  const goToNextStage = async () => {
    if (
      bookingItineraryStore.stayDates?.checkInDate !==
        searchStore.stayDates.checkInDate ||
      bookingItineraryStore.stayDates.checkOutDate !==
        searchStore.stayDates.checkOutDate
    ) {
      const { isCanceled, data: hasUserConfirmedDates } =
        await stayDateMismatchWarningModal.reveal();

      if (isCanceled) {
        return;
      }

      if (!hasUserConfirmedDates) {
        bookingItineraryStore.clearSelectedUnitItinerary();
        return;
      }
    }

    bookingStageStore.goToNextStage();
  };

  return {
    isSearchPropertyAvailabilityCalendarModalOpen,
    pricePlanSelectedMeals,
    pricePlans,
    stayDateMismatchWarningModal,
    updateProperty,
    updateStayDates,
    updateOccupancies,
    goToNextStage,
  };
});
