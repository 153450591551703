<template>
  <div
    class="flex justify-center p-4 capitalize text-sky-700 underline print:hidden"
  >
    <AppLink :text="toTitleCase($t('printBookingConfirmation'))" @click="print">
      <template #icon>
        <IconPrinter :size="28" />
      </template>
    </AppLink>
  </div>
</template>

<script setup lang="ts">
import { IconPrinter } from '@tabler/icons-vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLink from '@/ui/app-link/AppLink.vue';

const { toTitleCase } = useLocaleString();

const print = window.print;
</script>
