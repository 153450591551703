<template>
  <div class="relative min-h-[3rem]">
    <AppPopoverButton
      v-if="useAsPopoverButton"
      class="min-w-full"
      :as="AppButton"
      intent="secondary"
      :text="buttonText"
    />
    <AppButton
      v-else
      intent="secondary"
      class="min-w-full"
      :text="buttonText"
      @click="$emit('click')"
    />

    <AppSkeletonBox
      v-show="searchStore.isLoading"
      class="absolute inset-0 z-10 rounded"
    />
  </div>
</template>

<script setup lang="ts">
import { useSearchStore } from '@/search/search.store';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppPopoverButton from '@/ui/app-popover/AppPopoverButton.vue';
import AppSkeletonBox from '@/ui/app-skeleton-box/AppSkeletonBox.vue';

withDefaults(
  defineProps<{
    buttonText: string;
    useAsPopoverButton?: boolean;
  }>(),
  {
    useAsPopoverButton: false,
  },
);

defineEmits(['click']);

const searchStore = useSearchStore();
</script>
