<template>
  <div class="flex items-center justify-between">
    <AppLink
      class="mr-2 text-sm"
      :text="capitalize($t('addAdditionalUnitTypeName', { unitTypeName }))"
      :disabled="!canAddUnitOccupancy"
      @click="addUnitOccupancy"
    />

    <AppButton
      intent="primary"
      :text="searchButtonText"
      :icon="searchButtonIcon"
      class="min-w-[7rem]"
      @click="search"
    />
  </div>
</template>

<script setup lang="ts">
import { type Icon } from '@tabler/icons-vue';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppLink from '@/ui/app-link/AppLink.vue';

defineProps<{ searchButtonText: string; searchButtonIcon?: Icon }>();

const emit = defineEmits(['submit']);

const { capitalize } = useLocaleString();

const {
  canSubmit,
  canAddUnitOccupancy,
  unitTypeName,
  addUnitOccupancy,
  validate,
} = useOccupancySelectorStore();

const search = (): void => {
  validate();

  if (canSubmit.value) {
    emit('submit');
  }
};
</script>
