import type { Property, PropertyDTO } from '@/property/property';
import type { Chain, ChainDTO } from '@/widget/chain/chain';
import type {
  GoogleTagManagerContainer,
  GoogleTagManagerContainerDTO,
} from '@/widget/google/tag-manager/google-tag-manager-container';

export enum UnavailableSearchResultsPreference {
  UnavailableUnits = 'unavailable_units',
  AvailableAdditionalProperties = 'available_additional_properties',
}

export enum CalendarAvailabilityAwareness {
  Aware = 'aware',
  Unaware = 'unaware',
}

export interface Widget {
  id: string;
  properties: Property[];
  rankOrderedPropertyIds: number[][];
  chain: Chain | undefined;
  unavailableSearchResultsPreference: UnavailableSearchResultsPreference;
  calendarAvailabilityAwareness: CalendarAvailabilityAwareness;
  googleTagManagerContainer: GoogleTagManagerContainer | undefined;
}

export interface WidgetDTO
  extends Omit<Widget, 'properties' | 'chain' | 'googleTagManagerContainer'> {
  properties: PropertyDTO[];
  chain: ChainDTO | null;
  googleTagManagerContainer: GoogleTagManagerContainerDTO | null;
}
