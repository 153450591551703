<template>
  <div
    v-show="iFrameStatus === PcibCardCaptureIFrameStatus.FormError"
    ref="pcibCardCaptureErrorContainer"
    :class="{ 'animate-shake': shouldAnimateCardCaptureError }"
    class="mb-4 scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(space.4))]"
    @animationend="shouldAnimateCardCaptureError = false"
  >
    <PaymentsCardError
      :message="
        $t('pleaseEnsureAllYourCardDetailsHaveBeenCorrectlyEnteredBelow')
      "
    />
  </div>

  <div class="relative" :class="{ 'h-64 md:h-96': iFrameHeight === 0 }">
    <iframe
      v-if="iFrameUrl"
      ref="pcibCardCaptureIFrame"
      :src="iFrameUrl"
      :title="$t('cardCaptureForm')"
      class="w-full focus:outline-none"
      :height="iFrameHeight"
    ></iframe>

    <PcibCardCaptureLoadingOverlay :i-frame-status="iFrameStatus" />
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { onBeforeMount, ref } from 'vue';
import { submitPaymentEventBusKey } from '@/event-bus/event-bus';
import PaymentsCardError from '@/payments-page/PaymentsCardError.vue';
import {
  usePcibCardCaptureIFrame,
  PcibCardCaptureIFrameStatus,
} from '@/payments-page/pcib-card-capture/pcib-card-capture-iframe.composable';
import { usePcibCardCaptureStore } from '@/payments-page/pcib-card-capture/pcib-card-capture.store';
import PcibCardCaptureLoadingOverlay from '@/payments-page/pcib-card-capture/PcibCardCaptureLoadingOverlay.vue';

const pcibCardCaptureStore = usePcibCardCaptureStore();

const pcibCardCaptureIFrame = ref<HTMLIFrameElement | null>(null);
const pcibCardCaptureErrorContainer = ref<HTMLDivElement | null>(null);

const shouldAnimateCardCaptureError = ref(false);

const displayCardCaptureError = () => {
  shouldAnimateCardCaptureError.value = true;

  setTimeout(() => {
    pcibCardCaptureErrorContainer.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });
};

const { iFrameUrl, iFrameStatus, iFrameHeight, submitIFrame } =
  usePcibCardCaptureIFrame(pcibCardCaptureIFrame, displayCardCaptureError);

onBeforeMount(() => {
  void pcibCardCaptureStore.initialize();
});

useEventBus(submitPaymentEventBusKey).on(submitIFrame);
</script>
