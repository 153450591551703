import {
  IconApps,
  IconCreditCard,
  IconDoor,
  IconLock,
  IconUserPlus,
} from '@tabler/icons-vue';
import { useArrayFilter, useEventBus } from '@vueuse/core';
import { useArrayFindIndex } from '@vueuse/shared';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useAbandonedCartStore } from '@/abandoned-cart/abandoned-cart.store';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { BookingStage } from '@/booking-stage/booking-stage';
import { BookingStageType } from '@/booking-stage/booking-stage';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { submitPaymentEventBusKey } from '@/event-bus/event-bus';
import { useGuestStore } from '@/guest/guest.store';
import { useGuestDetailsStore } from '@/guest-details-page/guest-details.store';
import i18n from '@/i18n';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import router, {
  EXTRAS_ROUTE,
  GUEST_DETAILS_ROUTE,
  PAYMENTS_ROUTE,
  UNIT_SELECTION_ROUTE,
} from '@/router';
import { useSearchStore } from '@/search/search.store';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const { t } = i18n.global;

export const useBookingStageStore = defineStore('booking-stage', () => {
  const searchStore = useSearchStore();
  const unitSelectionStore = useUnitSelectionStore();
  const guestDetailsStore = useGuestDetailsStore();
  const guestStore = useGuestStore();
  const codeResourceStore = useCodeResourceStore();
  const bookingItineraryStore = useBookingItineraryStore();
  const abandonedCartStore = useAbandonedCartStore();

  const shouldShowDropdownSelector = ref(false);

  const allStages = computed(() => [
    unitSelectionStage.value,
    extrasStage.value,
    guestDetailsStage.value,
    paymentsStage.value,
  ]);

  const stages = useArrayFilter(
    allStages,
    ({ shouldBePresent }) => shouldBePresent,
  );

  const currentStageIndex = useArrayFindIndex(
    stages,
    ({ routeName }) => routeName === router.currentRoute.value.name,
  );

  const previousStageIndex = computed(() => currentStageIndex.value - 1);

  const nextStageIndex = computed(() => currentStageIndex.value + 1);

  const currentStage = computed(
    () => stages.value[currentStageIndex.value] ?? unitSelectionStage.value,
  );

  const previousStage = computed(() => stages.value[previousStageIndex.value]);

  const nextStage = computed(() => stages.value[nextStageIndex.value]);

  const hasPreviousStage = computed(() => !!previousStage.value);

  const completedStages = computed(() =>
    stages.value.slice(0, currentStageIndex.value),
  );

  const currentStageNumber = computed(() => currentStageIndex.value + 1);

  const totalNumberOfStages = computed(() => stages.value.length);

  const initialize = async () => {
    await Promise.all([
      searchStore.initialize(),
      codeResourceStore.initialize(),
    ]);

    codeResourceStore.validate(
      searchStore.stayDates,
      searchStore.activePropertyAvailability,
    );

    await bookingItineraryStore.initialize();

    if (
      bookingItineraryStore.stayDates &&
      bookingItineraryStore.propertyAvailability
    ) {
      codeResourceStore.validate(
        bookingItineraryStore.stayDates,
        bookingItineraryStore.propertyAvailability,
      );
    }

    const initialStage = allStages.value.find(
      ({ routeName }) => routeName === router.currentRoute.value.name,
    )!;

    if (initialStage.onInitialize) {
      initialStage.onInitialize();
    }

    bookingItineraryStore.syncBookingItineraryToQueryParams();
    await bookingItineraryStore.validateItineraryAvailability();

    void abandonedCartStore.initialize();
  };

  const goToStage = async (
    { type, routeName }: BookingStage,
    shouldReplaceRoute = false,
    shouldRefreshPropertyAvailability = true,
  ): Promise<void> => {
    if (type === currentStage.value.type) {
      return;
    }

    toast.clearAll();

    await router.push({
      name: routeName,
      replace: shouldReplaceRoute,
      params: {
        widgetId: searchStore.widget.id,
        propertyId: searchStore.activeProperty.id,
      },
    });

    if (shouldRefreshPropertyAvailability) {
      void bookingItineraryStore.refreshPropertyAvailability();
    }
  };

  const goToPreviousStage = () => {
    if (previousStage.value) {
      void goToStage(previousStage.value);
    }
  };

  const goToNextStage = () => {
    if (nextStage.value) {
      void goToStage(nextStage.value);
    }
  };

  const isStageCurrent = ({ type }: BookingStage): boolean =>
    isStageTypeCurrent(type);

  const isStageComplete = ({ type }: BookingStage): boolean =>
    isStageTypeComplete(type);

  const isStageTypeCurrent = (type: BookingStageType): boolean =>
    type === currentStage.value.type;

  const isStageTypeComplete = (type: BookingStageType): boolean =>
    completedStages.value.some(
      (completedStage) => type === completedStage.type,
    );

  const unitSelectionStage = computed<BookingStage>(() => ({
    type: BookingStageType.UnitSelection,
    routeName: UNIT_SELECTION_ROUTE,
    title: t('unitTypeNameSelection', {
      unitTypeName: useUnitType(
        computed(() => searchStore.activeProperty.unitType),
      ).unitTypeName.value,
    }),
    icon: IconDoor,
    bookingSummaryContinueButton: { text: t('book') },
    shouldBePresent: true,
    canAccess: true,
    requiresNonEmptyUnitItinerary: false,
    shouldDisplayTermsAndConditionsAgreement: false,
    shouldDisplayRecaptchaInformation: false,
    shouldDisplayGuestDetailsInSummary: false,
    shouldDisplayModalBookingSummaryFooterStayDates: false,
    goToNextStage: unitSelectionStore.goToNextStage,
  }));

  const extrasStage = computed<BookingStage>(() => {
    const { extras } = bookingItineraryStore.hasEmptyUnitItinerary
      ? searchStore.activePropertyOverStay
      : bookingItineraryStore.propertyOverStay;

    const mandatoryExtras = extras.filter(({ isMandatory }) => isMandatory);

    return {
      type: BookingStageType.Extras,
      routeName: EXTRAS_ROUTE,
      title: t('bookingExtras'),
      icon: IconApps,
      bookingSummaryContinueButton: {
        text:
          bookingItineraryStore.hasEmptySelectedExtraItinerary &&
          mandatoryExtras.length === 0
            ? t('skipExtras')
            : t('continue'),
      },
      shouldBePresent: extras.length > 0,
      canAccess: !bookingItineraryStore.hasEmptyUnitItinerary,
      requiresNonEmptyUnitItinerary: true,
      shouldDisplayTermsAndConditionsAgreement: false,
      shouldDisplayRecaptchaInformation: false,
      shouldDisplayGuestDetailsInSummary: false,
      shouldDisplayModalBookingSummaryFooterStayDates: true,
      goToNextStage,
      onInitialize: () => {
        if (bookingItineraryStore.hasEmptyUnitItinerary) {
          void goToStage(unitSelectionStage.value);
        }
      },
    };
  });

  const guestDetailsStage = computed<BookingStage>(() => {
    const onFinalStage = !bookingItineraryStore.hasPaymentGateway;

    return {
      type: BookingStageType.GuestDetails,
      routeName: GUEST_DETAILS_ROUTE,
      title: t('guestDetails'),
      icon: IconUserPlus,
      bookingSummaryContinueButton: onFinalStage
        ? { text: t('confirmBooking'), icon: IconLock }
        : { text: t('continue') },
      shouldBePresent: !searchStore.activePropertyCanUseExpressCheckout,
      canAccess: !bookingItineraryStore.hasEmptyUnitItinerary,
      requiresNonEmptyUnitItinerary: true,
      shouldDisplayTermsAndConditionsAgreement: false,
      shouldDisplayRecaptchaInformation: onFinalStage,
      shouldDisplayGuestDetailsInSummary: false,
      shouldDisplayModalBookingSummaryFooterStayDates: true,
      goToNextStage: () => {
        void guestDetailsStore.goToNextStage(onFinalStage);
      },
      onInitialize: () => {
        if (bookingItineraryStore.hasEmptyUnitItinerary) {
          void goToStage(unitSelectionStage.value);
        } else if (searchStore.activePropertyCanUseExpressCheckout) {
          void goToStage(paymentsStage.value);
        }
      },
    };
  });

  const paymentsStage = computed<BookingStage>(() => ({
    type: BookingStageType.Payments,
    routeName: PAYMENTS_ROUTE,
    title: t('payments'),
    icon: IconCreditCard,
    bookingSummaryContinueButton: {
      text: t('confirmBooking'),
      icon: IconLock,
    },
    shouldBePresent: bookingItineraryStore.hasPaymentGateway,
    canAccess:
      !bookingItineraryStore.hasEmptyUnitItinerary &&
      (!guestDetailsStage.value.shouldBePresent ||
        guestDetailsStore.isFormValid),
    requiresNonEmptyUnitItinerary: true,
    shouldDisplayTermsAndConditionsAgreement:
      searchStore.activePropertyCanUseExpressCheckout,
    shouldDisplayRecaptchaInformation: false,
    shouldDisplayGuestDetailsInSummary: guestDetailsStage.value.shouldBePresent,
    shouldDisplayModalBookingSummaryFooterStayDates: true,
    goToNextStage: useEventBus(submitPaymentEventBusKey).emit,
    onInitialize: () => {
      if (bookingItineraryStore.hasEmptyUnitItinerary) {
        void goToStage(unitSelectionStage.value);
      } else if (
        guestDetailsStage.value.shouldBePresent &&
        !guestStore.isFullyDefined
      ) {
        void goToStage(guestDetailsStage.value);
      }
    },
  }));

  return {
    shouldShowDropdownSelector,
    stages,
    currentStage,
    hasPreviousStage,
    currentStageNumber,
    totalNumberOfStages,
    unitSelectionStage,
    guestDetailsStage,
    paymentsStage,
    initialize,
    isStageCurrent,
    isStageComplete,
    isStageTypeCurrent,
    goToStage,
    goToPreviousStage,
    goToNextStage,
  };
});
