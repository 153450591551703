import { findLastIndex, isEqual } from 'lodash-es';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { generateUniqueId } from '@/generator/generator.utilities';
import type { PricePlan } from '@/price-plan/price-plan';
import type { Meal } from '@/property/meal/meal';
import { occupancyLimitsCanFitOccupancy } from '@/property/occupancy-limits/occupancy-limits.utilities';
import type { PropertyOverStay } from '@/property/property-over-stay/property-over-stay';

export const doesPricePlanMatchUnitItineraryItem = (
  pricePlan: PricePlan,
  unitItineraryItem: UnitItineraryItem,
): boolean =>
  pricePlan.unit.id === unitItineraryItem.unitId &&
  pricePlan.wayToSell?.id === unitItineraryItem.wayToSellId &&
  isEqual(pricePlan.occupancy, unitItineraryItem.occupancy) &&
  pricePlan.offer?.id === unitItineraryItem.offerId;

export const convertPricePlanToUnitItineraryItem = (
  pricePlan: PricePlan,
  selectedMeals: Meal[],
): UnitItineraryItem => ({
  id: generateUniqueId(),
  unitId: pricePlan.unit.id,
  wayToSellId: pricePlan.wayToSell?.id,
  offerId: pricePlan.offer?.id,
  occupancy: pricePlan.occupancy,
  selectedMealTypes: selectedMeals.map(({ type }) => type),
  leadGuestName: '',
  twinDoubleBedOption: undefined,
});

export const findLastIndexOfUnitItineraryItem = (
  unitItineraryItemToFind: UnitItineraryItem,
  unitItineraryItems: UnitItineraryItem[],
): number =>
  findLastIndex(
    unitItineraryItems,
    (unitItineraryItem) =>
      unitItineraryItemToFind.unitId === unitItineraryItem.unitId &&
      unitItineraryItemToFind.wayToSellId === unitItineraryItem.wayToSellId &&
      unitItineraryItemToFind.offerId === unitItineraryItem.offerId &&
      isEqual(unitItineraryItemToFind.occupancy, unitItineraryItem.occupancy),
  );

export const countPricePlanQuantitySelectedInUnitItinerary = (
  pricePlan: PricePlan,
  unitItinerary: UnitItineraryItem[],
) =>
  unitItinerary.filter((unitItineraryItem) =>
    doesPricePlanMatchUnitItineraryItem(pricePlan, unitItineraryItem),
  ).length;

export const isUnitItineraryAvailable = (
  unitItinerary: UnitItineraryItem[],
  propertyOverStay: PropertyOverStay,
): boolean => {
  if (unitItinerary.length === 0) {
    return true;
  }

  return (
    !propertyOverStay.isClosedToArrival &&
    !propertyOverStay.isClosedToDeparture &&
    unitItinerary.every((unitItineraryItem) =>
      isUnitItineraryItemAvailable(unitItineraryItem, propertyOverStay),
    )
  );
};

const isUnitItineraryItemAvailable = (
  {
    unitId,
    wayToSellId,
    offerId,
    selectedMealTypes,
    occupancy,
  }: UnitItineraryItem,
  {
    findUnitOverStayByUnitId,
    isOfferAvailable,
    isSupplementalMealAvailable,
  }: PropertyOverStay,
): boolean => {
  const unitOverStay = findUnitOverStayByUnitId(unitId);

  if (!unitOverStay) {
    return false;
  }

  if (!unitOverStay.isAvailable) {
    return false;
  }

  if (unitOverStay.numberAvailableAfterSelection < 0) {
    /**
     * This may occur if the unit is not fully sold out, but no longer has
     * enough allocation to accommodate the previously selected number
     * of units.
     */
    return false;
  }

  let { occupancyLimits } = unitOverStay.unit;

  if (wayToSellId) {
    const wayToSell = unitOverStay.findAvailableWayToSellById(wayToSellId);

    if (!wayToSell) {
      return false;
    }

    occupancyLimits = wayToSell.occupancyLimits;
  }

  if (!occupancyLimitsCanFitOccupancy(occupancyLimits, occupancy)) {
    return false;
  }

  if (offerId && !isOfferAvailable(offerId)) {
    return false;
  }

  return selectedMealTypes.every((selectedMealType) =>
    isSupplementalMealAvailable(selectedMealType),
  );
};
