<template>
  <AppLayout :property="undefined">
    <ErrorLayout>
      <Maintenance />
    </ErrorLayout>
  </AppLayout>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import Maintenance from '@/error-pages/maintenance/MaintenanceError.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';

const { t } = useI18n();

useHead({
  title: () => t('maintenance'),
});
</script>
