import { HTTPError } from 'ky';
import { computed } from 'vue';
import type { CustomAttributes } from '@/log/log.utilities';
import { logError as logBasicError } from '@/log/log.utilities';
import { useQueryParams } from '@/url/query-params/query-params.composable';

export const useLogger = () => {
  const queryParameters = useQueryParams();

  const additionalCustomAttributes = computed<CustomAttributes>(() => ({
    searchStayDates: JSON.stringify(
      queryParameters.searchStayDatesQueryParam.value,
    ),
    searchOccupancy: JSON.stringify(
      queryParameters.searchOccupanciesQueryParam.value,
    ),
    bookingItinerary: JSON.stringify(
      queryParameters.bookingItineraryQueryParam.value,
    ),
    bookingSource: JSON.stringify(
      queryParameters.bookingSourceQueryParam.value,
    ),
    locale: JSON.stringify(queryParameters.localeQueryParam.value),
  }));

  const logError = async (error: Error, customAttributes: CustomAttributes) => {
    if (error instanceof HTTPError) {
      customAttributes['httpRequestBaseUrl'] = getBaseUrlFromHttpError(error);
      customAttributes['httpResponseStatus'] = error.response.status;
      customAttributes['httpResponseBody'] = await error.response.text();
    }

    logBasicError(error, {
      ...customAttributes,
      ...additionalCustomAttributes.value,
    });
  };

  return {
    logError,
  };
};

const getBaseUrlFromHttpError = (error: HTTPError): string => {
  try {
    const { origin, pathname } = new URL(error.request.url);

    return origin + pathname;
  } catch (_error) {
    return 'unknown';
  }
};
