<template>
  <section data-test="UnitSelectionPricePlan" class="grid gap-2">
    <UnitSelectionPricePlanHeader :price-plan />

    <UnitSelectionPricePlanStatuses :price-plan />

    <UnitSelectionPricePlanDiscountTag
      v-if="pricePlan.type === PricePlanVariantType.Discounted"
      :price-plan
    />

    <UnitSelectionPricePlanPromocodeDiscountTag v-if="promocode" :promocode />

    <UnitSelectionPricePlanOccupancy :price-plan />

    <UnitSelectionPricePlanPackage
      v-if="pricePlan.type === PricePlanVariantType.Package"
      :price-plan
    />

    <UnitSelectionMealPricePlans
      v-else-if="pricePlan.type !== PricePlanVariantType.Private"
      :price-plan
    />

    <UnitSelectionPricePlanContingencyCharge
      v-if="contingencyCharge && contingencyChargeUnit"
      :contingency-charge
      :contingency-charge-unit
    />

    <UnitSelectionPricePlanFooter :price-plan />
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { findPropertyContingencyChargeUnitById } from '@/contingency-charge/property/property-contingency-charge.utilities';
import type { PricePlan } from '@/price-plan/price-plan';
import { PricePlanVariantType } from '@/price-plan/price-plan';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionPricePlanContingencyCharge from '@/unit-selection-page/price-plan/contingency-charge/UnitSelectionPricePlanContingencyCharge.vue';
import UnitSelectionPricePlanDiscountTag from '@/unit-selection-page/price-plan/discount-tag/UnitSelectionPricePlanDiscountTag.vue';
import UnitSelectionPricePlanFooter from '@/unit-selection-page/price-plan/footer/UnitSelectionPricePlanFooter.vue';
import UnitSelectionPricePlanHeader from '@/unit-selection-page/price-plan/header/UnitSelectionPricePlanHeader.vue';
import UnitSelectionMealPricePlans from '@/unit-selection-page/price-plan/meal-price-plan/UnitSelectionMealPricePlans.vue';
import UnitSelectionPricePlanOccupancy from '@/unit-selection-page/price-plan/occupancy/UnitSelectionPricePlanOccupancy.vue';
import UnitSelectionPricePlanPackage from '@/unit-selection-page/price-plan/package/UnitSelectionPricePlanPackage.vue';
import UnitSelectionPricePlanPromocodeDiscountTag from '@/unit-selection-page/price-plan/promocode-discount-tag/UnitSelectionPricePlanPromocodeDiscountTag.vue';
import UnitSelectionPricePlanStatuses from '@/unit-selection-page/price-plan/status/UnitSelectionPricePlanStatuses.vue';

const props = defineProps<{
  pricePlan: PricePlan;
}>();

const searchStore = useSearchStore();

const promocode = computed(() =>
  props.pricePlan.type !== PricePlanVariantType.Package &&
  props.pricePlan.type !== PricePlanVariantType.Private
    ? props.pricePlan.promocode
    : undefined,
);

const contingencyCharge = computed(
  () => searchStore.activeProperty.contingencyCharge,
);

const contingencyChargeUnit = computed(() =>
  contingencyCharge.value
    ? findPropertyContingencyChargeUnitById(
        contingencyCharge.value,
        props.pricePlan.unit.id,
      )
    : undefined,
);
</script>
