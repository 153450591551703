import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from '@/App.vue';
import i18n from '@/i18n';
import '@stripe/stripe-js';
import { useLogger } from '@/log/log.composable';
import router from '@/router';
import toast, { generateToastOptions } from '@/toast';
import '@/assets/css/index.css';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/pagination';

const app = createApp(App)
  .use(createHead())
  .use(createPinia())
  .use(VueGtag, {})
  .use(router)
  .use(i18n);

app.use(toast, generateToastOptions(app));

const { logError } = useLogger();

app.config.errorHandler = (error, instance, info) => {
  void logError(
    error instanceof Error ? error : new Error(JSON.stringify(error)),
    {
      vueComponentName: instance?.$.type.name ?? 'unknown',
      vueErrorSourceType: info,
    },
  );
};

app.mount('#app');
