<template>
  <AppLink
    :text="$t('remove')"
    class="justify-self-end text-xs font-medium capitalize text-gray-600"
    @click="extraItineraryItemStore.quantity = 0"
  />
</template>

<script setup lang="ts">
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import type { NonMandatoryExtra } from '@/property/extra/extra';
import AppLink from '@/ui/app-link/AppLink.vue';

const props = defineProps<{
  extra: NonMandatoryExtra;
}>();

const extraItineraryItemStore = useExtraItineraryItemStore(props.extra);
</script>
