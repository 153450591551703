<template>
  <VanityView v-if="isInitialized" />

  <LoadingView v-else />
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import LoadingView from '@/loading-overlay/LoadingOverlay.vue';
import { UNIT_SELECTION_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import VanityView from '@/vanity-page/VanityView.vue';

const router = useRouter();

const searchStore = useSearchStore();

const isInitialized = ref(false);

const initialize = async () => {
  await searchStore.initialize();

  if (searchStore.activeProperty.propertyVanity) {
    isInitialized.value = true;
  } else {
    await goToUnitSelection();
  }
};

const goToUnitSelection = () =>
  router.replace({
    name: UNIT_SELECTION_ROUTE,
  });

onBeforeMount(initialize);
</script>
