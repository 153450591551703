<template>
  <AppModal
    class="z-30"
    :is-open="unitSelectionStore.stayDateMismatchWarningModal.isRevealed"
    @close="unitSelectionStore.stayDateMismatchWarningModal.cancel"
  >
    <AppModalDialog
      class="sm:max-w-2xl"
      @close="unitSelectionStore.stayDateMismatchWarningModal.cancel"
    >
      <template #header>
        <span class="font-semibold capitalize">
          {{ $t('confirmStayDates') }}
        </span>
      </template>

      <div class="space-y-6">
        <p class="first-letter:capitalize">
          {{
            $t('youHaveSelectedUnitsOnTheFollowingDates', {
              unitTypeNamePlural,
            })
          }}:
        </p>

        <div class="flex items-center justify-center">
          <div class="mr-2">
            <IconCalendar :size="18" />
          </div>

          <AppDateRange
            class="font-semibold"
            :from-date="bookingItineraryStore.stayDates!.checkInDate"
            :to-date="bookingItineraryStore.stayDates!.checkOutDate"
          />
        </div>

        <p class="first-letter:capitalize">
          {{ $t('wouldYoulikeToContinueYourBookingOnThisDateRange') }}
        </p>
      </div>

      <template #footer>
        <div class="flex justify-between space-x-4">
          <AppButton
            intent="secondary"
            class="w-full"
            :text="capitalize($t('noClearMySelection'))"
            @click="
              unitSelectionStore.stayDateMismatchWarningModal.confirm(false)
            "
          />

          <AppButton
            intent="primary"
            class="w-full capitalize"
            :text="$t('continue')"
            @click="
              unitSelectionStore.stayDateMismatchWarningModal.confirm(true)
            "
          />
        </div>
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import { IconCalendar } from '@tabler/icons-vue';
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppButton from '@/ui/app-button/AppButton.vue';
import AppDateRange from '@/ui/app-date-range/AppDateRange.vue';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();
const bookingItineraryStore = useBookingItineraryStore();

const { capitalize } = useLocaleString();

const { unitTypeNamePlural } = useUnitType(
  computed(() => searchStore.activeProperty.unitType),
);
</script>
