<template>
  <div class="bg-white">
    <div
      class="relative flex flex-col items-center justify-center overflow-hidden bg-accent-color"
      :class="{
        'animate-pulse': searchStore.isUpdatingProperty,
        'h-[300px] md:h-[400px]':
          searchStore.activeProperty.propertyTheme.header,
      }"
    >
      <UnitSelectionCustomHeaderImage
        v-if="searchStore.activeProperty.propertyTheme.header"
        :property-header="searchStore.activeProperty.propertyTheme.header"
      />

      <div
        v-if="
          searchStore.activeProperty.propertyTheme.logo || propertyNameToDisplay
        "
        class="z-10 flex h-full w-full flex-col items-center justify-center"
        :class="{
          'bg-accent-color/40':
            searchStore.activeProperty.propertyTheme.header?.isOverlayEnabled,
        }"
      >
        <UnitSelectionCustomHeaderLogo
          v-if="searchStore.activeProperty.propertyTheme.logo"
          :property-logo="searchStore.activeProperty.propertyTheme.logo"
        />

        <UnitSelectionCustomHeaderName
          v-if="propertyNameToDisplay"
          class="p-6"
          :class="
            searchStore.activeProperty.propertyTheme.header
              ? 'themeName'
              : 'pb-2 text-accent-contrast-color'
          "
          :property-name="propertyNameToDisplay"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useSearchStore } from '@/search/search.store';
import UnitSelectionCustomHeaderImage from '@/unit-selection-page/custom-header/UnitSelectionCustomHeaderImage.vue';
import UnitSelectionCustomHeaderLogo from '@/unit-selection-page/custom-header/UnitSelectionCustomHeaderLogo.vue';
import UnitSelectionCustomHeaderName from '@/unit-selection-page/custom-header/UnitSelectionCustomHeaderName.vue';

const searchStore = useSearchStore();

// force display property name if the widget is multi-property
const propertyNameToDisplay = computed(() =>
  searchStore.activeProperty.propertyTheme.name
    ? searchStore.activeProperty.propertyTheme.name.text
    : searchStore.additionalProperties.length > 0
      ? searchStore.activeProperty.name
      : undefined,
);
</script>

<style scoped>
.themeName {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  color: white;
}
</style>
