<template>
  <i18n-t
    v-if="bookingStageStore.currentStage.shouldDisplayRecaptchaInformation"
    class="text-xs text-slate-700"
    keypath="thisSiteIsProtectedByReCaptcha"
    tag="p"
    scope="global"
  >
    <template #privacyPolicy>
      <a
        class="text-blue-600 underline"
        href="https://policies.google.com/privacy"
        target="_blank"
      >
        {{ $t('privacyPolicy') }}
      </a>
    </template>

    <template #termsOfService>
      <a
        class="text-blue-600 underline"
        href="https://policies.google.com/terms"
        target="_blank"
      >
        {{ $t('termsOfService') }}
      </a>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const bookingStageStore = useBookingStageStore();
</script>
