import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Booking } from '@/booking/booking';
import type { BookingExtra } from '@/booking/extra/booking-extra';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';
import { GoogleTagManagerPageViewEventAction } from '@/widget/google/tag-manager/google-tag-manager-event-action';

export const useGoogleTagManagerStore = defineStore(
  'google-tag-manager-store',
  () => {
    const gtmLoaded = ref(false);

    const emitGTMPageViewEvent = (
      action: GoogleTagManagerPageViewEventAction,
      propertyId: number,
      stayDates?: StayDates,
    ) => {
      if (gtmLoaded.value && window.dataLayer) {
        window.dataLayer.push({
          event: 'page_view',
          page_name: action,
          property_id: propertyId,
          stay_dates: stayDates,
          stay_length: stayDates ? getStayLengthFromStayDates(stayDates) : null,
        });
      }
    };

    const emitGTMPurchaseEvent = (booking: Booking) => {
      if (gtmLoaded.value && window.dataLayer) {
        window.dataLayer.push({
          event: 'purchase',
          property_id: booking.property.id,
          booking_id: booking.id,
          transaction_id: booking.id,
          affiliation: 'Freetobook',
          value: booking.totalAmount,
          currency: booking.property.currencyCode,
          coupon: booking.promocode?.promocodeText ?? null,
          items: [
            ...booking.activeBookingUnits.map((unit: ActiveBookingUnit) => ({
              id: unit.unitId,
              name: unit.unitName,
              price: unit.rate,
            })),
            ...booking.bookingExtras.map((extra: BookingExtra) => ({
              name: extra.extraName,
              quantity: extra.quantity,
              price: extra.itemCharge,
            })),
          ],
        });
      }
    };

    /**
     * @link https://developers.google.com/tag-platform/tag-manager/datalayer#installation
     */
    const initializeGTM = (containerId: string) => {
      if (!gtmLoaded.value) {
        window.dataLayer = window.dataLayer ?? [];
        const script = document.createElement('script');
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${containerId}');
        `;
        document.head.appendChild(script);
        gtmLoaded.value = true;
      }
    };

    return {
      emitGTMPageViewEvent,
      emitGTMPurchaseEvent,
      initializeGTM,
    };
  },
);
