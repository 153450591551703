<template>
  <div class="flex items-center justify-between">
    <div class="mr-2 text-lg font-medium capitalize">
      {{
        $t('unitTypeNameNumber', {
          unitTypeName,
          number: roomNumber,
        })
      }}
    </div>

    <AppLink
      class="text-right text-sm"
      :text="capitalize($t('removeItemName', { itemName: unitTypeName }))"
      @click="removeUnitOccupancy(unitOccupancy.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import type { OccupancySelectorUnitOccupancy } from '@/occupancy-selector/unit-occupancy/occupancy-selector-unit-occupancy';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLink from '@/ui/app-link/AppLink.vue';

defineProps<{
  unitOccupancy: OccupancySelectorUnitOccupancy;
  roomNumber: number;
}>();

const { capitalize } = useLocaleString();

const { unitTypeName, removeUnitOccupancy } = useOccupancySelectorStore();
</script>
