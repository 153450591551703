<template>
  <DialogPanel
    class="flex max-h-[90%] w-full flex-col overflow-hidden rounded-t-xl bg-white shadow-xl sm:max-h-full sm:rounded-xl"
  >
    <header
      v-if="!!$slots['header']"
      class="flex items-center justify-between border-b border-neutral-200 px-6 py-4"
    >
      <DialogTitle class="mr-4">
        <slot name="header"></slot>
      </DialogTitle>

      <AppCloseButton v-if="shouldShowCloseButton" @close="$emit('close')" />
    </header>

    <div
      ref="body"
      class="overflow-y-auto"
      :tabindex="isBodyOverflowing ? 0 : -1"
      :class="combineClasses('px-6 py-4', bodyClass)"
      role="region"
    >
      <slot></slot>
    </div>

    <footer v-if="!!$slots['footer']">
      <div class="border-t border-neutral-200 px-6 py-4">
        <slot name="footer"></slot>
      </div>
    </footer>
  </DialogPanel>
</template>

<script setup lang="ts">
import { DialogPanel, DialogTitle } from '@headlessui/vue';
import { useResizeObserver } from '@vueuse/core';
import { ref, useTemplateRef } from 'vue';
import AppCloseButton from '@/ui/app-close-button/AppCloseButton.vue';
import { combineClasses, type ClassValue } from '@/ui/ui.utilities';

const { shouldShowCloseButton = true } = defineProps<{
  shouldShowCloseButton?: boolean;
  bodyClass?: ClassValue;
}>();

defineEmits(['close']);

const body = useTemplateRef('body');

const isBodyOverflowing = ref(false);

useResizeObserver(body, ([entry]) => {
  if (!entry) {
    return;
  }

  const { scrollHeight, clientHeight } = entry.target;

  isBodyOverflowing.value = scrollHeight > clientHeight;
});
</script>
