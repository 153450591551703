<template>
  <OccupancySelectorBodyContainer>
    <div v-for="selectorType of selectorTypes" :key="selectorType.id">
      <div
        v-if="selectorType.isUnitOccupancySelector"
        :class="{ 'pt-8': selectorType.roomNumber !== 1 }"
      >
        <OccupancySelectorUnitOccupancySelector
          :unit-occupancy="selectorType.unitOccupancy"
          :room-number="selectorType.roomNumber"
          :show-room-number="unitOccupancies.length > 1"
        />
      </div>

      <div v-else-if="childPolicy.childrenAreAllowed" class="pt-3">
        <OccupancySelectorChildAgeSelector
          :child-occupant="selectorType.childOccupant"
          :minimum-child-age="childPolicy.minimumChildAge"
          :maximum-child-age="childPolicy.maximumChildAge"
        />
      </div>
    </div>
  </OccupancySelectorBodyContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { OccupancySelectorChildOccupant } from '@/occupancy-selector/child-occupant/occupancy-selector-child-occupant';
import { useOccupancySelectorStore } from '@/occupancy-selector/occupancy-selector.store';
import OccupancySelectorBodyContainer from '@/occupancy-selector/OccupancySelectorBodyContainer.vue';
import OccupancySelectorChildAgeSelector from '@/occupancy-selector/OccupancySelectorChildAgeSelector.vue';
import OccupancySelectorUnitOccupancySelector from '@/occupancy-selector/OccupancySelectorUnitOccupancySelector.vue';
import type { OccupancySelectorUnitOccupancy } from '@/occupancy-selector/unit-occupancy/occupancy-selector-unit-occupancy';

type SelectorType = {
  id: string;
} & (
  | {
      isUnitOccupancySelector: true;
      roomNumber: number;
      unitOccupancy: OccupancySelectorUnitOccupancy;
    }
  | {
      isUnitOccupancySelector: false;
      childOccupant: OccupancySelectorChildOccupant;
    }
);

const { property, unitOccupancies } = useOccupancySelectorStore();

const childPolicy = computed(() => property.value.childPolicy);

const selectorTypes = computed<SelectorType[]>(() => {
  let roomNumber = 1;

  return unitOccupancies.value.flatMap<SelectorType>((unitOccupancy) => [
    {
      id: unitOccupancy.id,
      isUnitOccupancySelector: true,
      roomNumber: roomNumber++,
      unitOccupancy,
    },
    ...unitOccupancy.childOccupants.map<SelectorType>((childOccupant) => ({
      id: childOccupant.id,
      isUnitOccupancySelector: false,
      childOccupant,
    })),
  ]);
});
</script>
