<template>
  <label class="group relative flex w-full flex-1">
    <span
      v-if="icon"
      class="absolute inset-y-0 flex items-center pl-3"
      :class="iconColorClassName"
    >
      <Component :is="icon" :size="22" />
    </span>

    <input
      ref="inputBox"
      v-model="input"
      type="text"
      :placeholder="placeholderText"
      class="flex h-10 w-full flex-1 appearance-none items-center rounded border placeholder:text-slate-500 focus:shadow-[0_0_0_1px] focus:outline-none"
      :class="inputBoxClassName"
      @keypress.enter="$emit('enter')"
    />

    <span
      class="pointer-events-none absolute inset-x-3 inset-y-0 flex items-center justify-end"
    >
      <AppLoadingSpinner v-if="successState === 'pending'" class="w-5" />
      <IconExclamationCircle
        v-else-if="successState === 'error'"
        :size="28"
        class="text-red-500"
      />
      <IconCircleCheck
        v-else-if="successState === 'valid'"
        :size="28"
        class="text-green-600"
      />
    </span>
  </label>
</template>

<script setup lang="ts">
import type { Icon } from '@tabler/icons-vue';
import { IconExclamationCircle, IconCircleCheck } from '@tabler/icons-vue';
import { computed, onMounted, ref } from 'vue';
import AppLoadingSpinner from '@/ui/app-loading-spinner/AppLoadingSpinner.vue';

const {
  icon,
  shouldAutoFocus = false,
  successState = 'normal',
} = defineProps<{
  placeholderText: string;
  icon?: Icon;
  shouldAutoFocus?: boolean;
  successState?: 'valid' | 'pending' | 'normal' | 'error';
}>();

const iconColorClassName = computed(() => {
  switch (successState) {
    case 'error':
      return 'text-red-500';
    case 'valid':
      return 'text-green-600';
    default:
      if (input.value.length > 0) {
        return 'text-slate-800';
      }
      return 'text-slate-500';
  }
});

const inputBoxClassName = computed(() => {
  let inputBoxColorStyle;
  switch (successState) {
    case 'error':
      inputBoxColorStyle = 'text-red-500 border-red-500';
      break;
    case 'valid':
      inputBoxColorStyle = 'text-green-600 border-green-600';
      break;
    default:
      inputBoxColorStyle = 'border-slate-800';
  }

  const inputBoxLeftPaddingStyle = icon ? 'pl-11' : 'pl-2';
  const inputBoxRightPaddingStyle =
    successState !== 'normal' ? 'pr-12' : 'pr-2';

  return `${inputBoxLeftPaddingStyle} ${inputBoxRightPaddingStyle} ${inputBoxColorStyle}`;
});

defineEmits(['enter']);

const input = defineModel<string>({ required: true });

const inputBox = ref<HTMLElement>();

onMounted(() => {
  if (shouldAutoFocus) {
    inputBox.value!.focus();
  }
});
</script>
