<template>
  <p>
    {{
      $t('includesContingencyChargeOfAmount', {
        amount: $n(contingencyCharge.amount, {
          style: 'currency',
          currency: bookingConfirmationStore.property.currencyCode,
        }),
        contingencyChargeName,
      })
    }}
  </p>
</template>

<script setup lang="ts">
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import type { BookingUnitContingencyCharge } from '@/contingency-charge/booking-unit/booking-unit-contingency-charge';
import { useContingencyChargeName } from '@/contingency-charge/name/contingency-charge-name.composable';

const { contingencyCharge } = defineProps<{
  contingencyCharge: BookingUnitContingencyCharge;
}>();

const bookingConfirmationStore = useBookingConfirmationStore();

const contingencyChargeName = useContingencyChargeName(() => contingencyCharge);
</script>
