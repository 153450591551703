<template>
  <div class="space-y-3 whitespace-pre-line text-sm">
    <p class="font-semibold">{{ $t('privacyStatement') }}</p>

    <p class="font-semibold">
      {{ $t('freetobookIsBookingSoftwareUsedByAccommodationProviders') }}
    </p>

    <p>
      {{ $t('thisDocumentAppliesToTheProcessingByFreetobookOfPersonalData') }}
    </p>

    <ul class="space-y-4">
      <li>
        <p class="font-semibold">{{ $t('1YourPersonalData') }}</p>

        <p class="mb-2">
          {{
            $t(
              'propertiesUsingFreetobookAsTheirBookingEngineCollectAndStoreInformationThatYouGiveWhenYouMakeABooking',
            )
          }}
        </p>

        <p>
          {{
            $t(
              'yourDataIsAlsoStoredAfterYouHaveStayedButWillBeKeptNoLongerThanNecessaryInOrderToComplyWithLegalObligationsAndForPropertiesToWelcomeYourRepeatBusiness',
            )
          }}
        </p>
      </li>

      <li>
        <p class="font-semibold">
          {{ $t('2DataProcessedDirectlyByThirdParties') }}
        </p>

        <p>
          {{
            $t(
              'ifYouProvidePaymentInformationThisWillBeProcessedDirectlyBySecurePCILevel1CompliantPaymentGatewayProviders',
            )
          }}
        </p>
      </li>

      <li>
        <p class="font-semibold">{{ $t('3JointControllers') }}</p>

        <p>
          {{
            $t(
              'inTheContextOfProvidingBookingSoftwareToAccommodationProvidersToFacilitateOnlineBookingFreetobookAndTheAccommodationProviderShallTogetherOperateAsJointDataControllersForTheProcessingOfYourPersonalData',
            )
          }}
        </p>
      </li>

      <li>
        <p class="font-semibold">{{ $t('4SecurityProcedures') }}</p>

        <p>
          {{
            $t(
              'inAccordanceWithGeneralDataProtectionRegulationGDPRFreetobookObservesReasonableProceduresToPreventUnauthorisedAccessAndMisuseOfPersonalInformation',
            )
          }}
        </p>
      </li>

      <li>
        <p class="font-semibold">{{ $t('5ControlOfYourPersonalData') }}</p>

        <p class="mb-2">
          {{
            $t(
              'youHaveTheRightToAccessDataThatIsStoredOnBehalfOfPropertiesTakingBookingsViaFreetobookSystem',
            )
          }}
        </p>

        <p>
          {{
            $t(
              'youCanAlsoContactThePropertyYouBookedWithOnFreetobookSystemIfYouBelieveThatThePersonalInformationTheyHaveForYouIsIncorrect',
            )
          }}
        </p>
      </li>

      <li>
        <p class="font-semibold">{{ $t('6Reviews') }}</p>

        <p>
          {{
            $t(
              'youMayAlsoBeAskedToSendFeedbackAfterYourStayInOrderForThePropertyToFurtherImproveTheirService',
            )
          }}
        </p>
      </li>
    </ul>

    <div class="font-semibold">
      {{
        $t(
          'toProcessYourInformationAsDescribedAboveWeRelyOnTheFollowingLegalBases',
        )
      }}
    </div>

    <ul class="space-y-4">
      <li>
        <span class="font-semibold">{{ $t('performanceOfAContract') }}</span>
        {{
          $t(
            'theStorageOfYourInformationMayBeNecessaryToPerformTheContractThatYouHaveWithTheAccommodationProviderYouAreBookingWith',
          )
        }}
      </li>

      <li>
        <span class="font-semibold">{{ $t('legitimateInterests') }}</span>
        {{
          $t(
            'weMayUseYourInformationForOurLegitimateInterestsSuchAsForAdministrativeFraudDetectionAndLegalPurposes',
          )
        }}
      </li>

      <li>
        <p class="mb-2 font-semibold">{{ $t('cookies') }}</p>

        <p>
          {{
            $t(
              'aCookieIsASmallAmountOfDataThatIsPlacedInTheBrowserOfYourComputerOrOnYourMobileDevice',
            )
          }}
        </p>
      </li>
    </ul>
  </div>
</template>
