import { Decimal } from 'decimal.js';
import { applyTaxPercentageToValue } from '@/tax/tax.utilities';
import type { TaxableItem } from '@/tax/taxable-item/taxable-item';

export const calculateVatAmountOfTaxableItems = (
  taxableItems: TaxableItem[],
): { included: number; excluded: number } => {
  const { included, excluded } =
    calculateDecimalVatAmountOfTaxableItems(taxableItems);

  return { included: included.toNumber(), excluded: excluded.toNumber() };
};

const calculateDecimalVatAmountOfTaxableItems = (
  taxableItems: TaxableItem[],
): { included: Decimal; excluded: Decimal } =>
  taxableItems.reduce(
    (vatAmounts, { isIncluded, taxPercentage, taxableAmount }) => {
      if (isIncluded) {
        vatAmounts.included = vatAmounts.included.add(
          applyTaxPercentageToValue(taxPercentage, taxableAmount, true),
        );
      } else {
        vatAmounts.excluded = vatAmounts.excluded.add(
          applyTaxPercentageToValue(taxPercentage, taxableAmount, false),
        );
      }

      return vatAmounts;
    },
    { included: new Decimal(0), excluded: new Decimal(0) },
  );
